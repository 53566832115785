.livesession-content-main {
  width: 100vw;
  height: 100vh;
  background: none;
  position: absolute;
  margin-top: 3rem;

  .live-sessions-list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 35px 20px;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 13rem);

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: fit-content;
      cursor: pointer;
      box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
      border-radius: 0px 10px 10px 10px;
      margin-bottom: 2rem;
      margin-right: 6rem;
      margin-left: 2rem;

      &:hover {
        transform: scale(1.08);
        transition: transform 0.2s ease-in-out;
      }
      .card-number-indicator {
        width: 30%;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
        border-radius: 10px 10px 10px 0px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 11px;
        color: #ffffff;
        padding: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .cards-wrapper {
        display: flex;
        flex-direction: column;
        width: 514px;
        height: 230px;
        padding: 22px 20px;
        background: #ffffff;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
        border-radius: 0px 10px 10px 10px;

        .image-spkname-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 66px;
            object-fit: contain;
          }
          .spkname-wrapper {
            display: flex;
            flex-direction: column;
            font-family: "Lato";
            font-style: normal;
            color: #262626;
            margin-left: 1rem;
            .spkname {
              font-weight: 700;
              font-size: 15px;
              line-height: 18px;
            }
            .time-range {
              font-weight: 500;
              font-size: 11px;
              line-height: 20px;
            }
          }
        }
        .event-overview {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #262626;
          margin: 2rem;
          height: 80px;
        }
        .separator {
          border: 1px solid #e4e4e4;
        }
        .event-link-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          a:link {
            background-color: transparent;
            text-decoration-color: #299ef3;
          }
          .label {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;

            display: flex;
            align-items: center;

            color: #262626;
          }
          .value {
            color: #299ef3;
            padding-left: 5px;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .recurring-wrapper {
        }
        .slots-wrapper {
        }
      }
    }
  }
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: none;
  position: absolute;
  margin-top: 3rem;
  .information-box {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: calc(100vh - 14rem);
    overflow: hidden;
    width: 95%;
    .left-side-wrapper {
      display: flex;
      flex-direction: column;
      width: 30%;
      .speaker-wrapper {
        display: flex;
        flex-direction: row;
        margin: 1rem;
        img {
          width: 40px;
          height: 40px;
          border-radius: 66px;
          object-fit: contain;
        }
        .speaker-name {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          margin-left: 10px;
          display: flex;
          align-items: center;

          color: #262626;
        }
      }
      .event-detail-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        .row-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .left-label {
            display: flex;
            justify-content: flex-start;
            flex-basis: 40%;
            width: 100%;
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.2px;
            margin: 1rem;
            color: #262626;
          }
          .right-value {
            display: flex;
            justify-content: flex-start;
            flex-basis: 60%;
            width: 100%;
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            margin-left: 1rem;
            color: #262626;
          }
          .link-value {
            font-family: "Lato";
            font-style: italic;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            width: 60%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            text-decoration-line: underline;
            cursor: pointer;
            color: #299ef3;
          }
        }
      }
    }
    .divider {
      border: 1px solid #e4e4e4;
    }
    .right-side-info {
      display: flex;
      flex-direction: column;
      width: 70%;
      overflow: hidden;
      overflow-y: auto;
      .speaker-wrapper {
        display: flex;
        flex-direction: column;
        margin: 2rem;
        .heading {
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;

          display: flex;
          align-items: center;

          color: #262626;
        }
        .content {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          margin: 1rem 0;
          color: #262626;
        }
      }
      .event-wrapper {
        display: flex;
        flex-direction: column;
        margin: 2rem;
        .heading {
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;

          display: flex;
          align-items: center;

          color: #262626;
        }
        .content {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          margin: 1rem 0;
          color: #262626;
        }
      }
    }
  }
}
