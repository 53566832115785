@import '../../../Utils/colors.scss';

.set-time-wrapper {
    display: flex;
    flex-direction: column;
    .start-end-date-wrapper {
        display: flex;
        flex-direction: row;
        padding: 0.75rem;
        padding-bottom: 0;
        .input-fields-labels {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.75rem 0;
            flex-wrap: wrap;
    
            > span {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                color: #262626;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 16px;
            }
            .mandatory {
                color: $color-red;
                vertical-align: middle;
            }
            .form-control-root {
                margin-left: 0;
                height: 3.125rem;
                min-width: 26.5rem;
                background: $color-white;
                .select-tag-root {
                    max-width: 26.5rem;
                    .chips-container {
                        overflow: hidden;
                        overflow-x: auto;
                    }
                }
                .select-root {
                    width: 310px;
                    height: 100%;
                    background: $color-white;
                }
            }
    
            .date-picker-comp {
                margin-left: 0;
                width: 19.375rem;
                height: 3.125rem;
                background: $color-white;
                border-radius: 0.1875rem;
                .notched-override {
                border: none;
                border-radius: 0.1875rem;
                }
            }
    
        }
    }
    .duration-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0.75rem;
        padding-top: 0;
        .input-fields-labels {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            > span {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                color: #262626;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 16px;
            }
            .mandatory {
                color: $color-red;
                vertical-align: middle;
            }
        }
        .form-wrapper {
            display: flex;
            flex-direction: row;
            .form-control-root {
                margin-left: 0;
                background: $color-white;
                .select-tag-root {
                    height: unset;
                }
            }
        }
    }
    .button-section {
        display: flex;
        justify-content: center;
        padding: 0.75rem;
        padding-top: 0;
        .cancel-btn {
            margin-right: 1.25rem;
        }
    }
}