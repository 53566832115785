@import "./Stylesheets/Utils/fonts.scss";

body {
  margin: 0;
  font-family: $font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
#root {
  height: 100%;
  overflow: hidden;
}

// TODO: Need to research on this for responsive sizes based on screen resolutions

// html {
//   font-size: 16px;
// }

// @media only screen and (min-width: 720px) {
//   html {
//       font-size: 18px;
//   }
// }

// @media only screen and (min-width: 960px) {
//   html {
//       font-size: 20px;
//   }
// }

// @media only screen and (min-width: 1240px) {
//   html {
//       font-size: 24px;
//   }
// }

.gif-loader {
  background: url('./Assets/loading.gif') no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  background-color: #fffffffc;
}