@import "../../../../Stylesheets/Utils/fonts.scss";
.main-container {
  width: calc(100% - 2rem) !important;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

  .pagination {
    display: flex;
    justify-content: center;
  }

  .cohort-header {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    justify-content: space-between;
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
    padding-left: 1.375rem;

    color: #000000;
  }

  @media (min-width: 1200px) {
    .cohort-tiles-main-container {
      max-width: 100% !important;
    }
  }
  .cohort-tiles-main-container {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 1.25rem;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 17rem);

    .create-cohort-tiles-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-left: 2rem;
      width: 20rem;
      height: 11rem;
      background: #ffffff;
      border-radius: 0.3125rem;
      box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
      }

      .create-cohort-tiles-content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-lato;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8125rem;
        letter-spacing: 0.0125rem;
        color: #262626;
      }
    }

    .cohort-tiles-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2rem;
      margin-left: 2rem;
      width: 20rem;
      height: 11rem;
      background: #ffffff;
      border-radius: 0.3125rem;
      box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
      }

      .cohort-tiles-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .cohort-tile-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-family: $font-family-lato;
          font-style: normal;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.375rem;
          letter-spacing: 0.0125rem;
          color: #262626;
          position: relative;

          .wrap-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 5px;
            width: 50%;
            &[data-tooltip]:hover::before,
            &[data-tooltip]:hover::after {
              content: attr(data-tooltip);
              position: absolute;
              padding: 0.5rem;
              background-color: #333;
              color: #fff;
              border-radius: 5px;
              font-size: 10px;
              white-space: nowrap;
            }

            &[data-tooltip]:hover::before {
              top: calc(100%);
              left: 35%;
              transform: translateX(-50%);
              min-width: 4rem;
              max-width: 12rem;
              white-space: break-spaces;
            }

            &[data-tooltip]:hover::after {
              display: none;
            }
          }

          .cohort-tile-options {
            display: flex;
            align-items: center;
            width: 50%;
            .pubnub-switch-root {
              color: #0e1555;
            }
          }
        }
        .cohort-tile-date-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 1.3125rem;
          font-family: "Arial";
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #788188;
          .cohort-tile-date {
            &-start,
            &-end {
              display: flex;
              .label {
                font-family: $font-family-lato;
                font-weight: 700;
              }
            }
            &-start {
              .label {
                margin-left: 0.75rem;
              }
            }
            &-end {
              .label {
                margin-left: 2rem;
              }
            }
          }
        }
        .cohort-tile-program-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 1.3125rem;
          font-family: "Arial";
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #788188;
          .label {
            font-family: $font-family-lato;
            font-weight: 700;
            margin-left: 0.5rem;
          }
          .value {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .main-container {
    width: calc(100% - 2rem);
    min-height: 53.125rem;
    padding: 0 !important;
    margin-left: 1.8125rem !important;
    margin-top: 1.8125rem !important;
    margin-right: 1.8125rem !important;
    background: #ffffff;
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

    .cohort-header {
      font-family: $font-family-lato;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5rem;
      justify-content: center;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      padding-top: 1.8125rem;
      padding-bottom: 1.8125rem;
      padding-left: 1.375rem;

      color: #000000;
    }

    .cohort-tiles-main-container {
      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 1.25rem;
      justify-content: center;
      overflow: hidden;
      overflow-y: auto;
      height: calc(100vh - 13rem);

      .create-cohort-tiles-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        margin-left: 2rem;
        min-width: 0rem;
        height: 10rem;
        background: #ffffff;
        border-radius: 0.3125rem;
        box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease-in-out;
        }

        .create-cohort-tiles-content {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $font-family-lato;
          font-style: normal;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.8125rem;
          letter-spacing: 0.0125rem;
          color: #262626;
        }
      }

      .cohort-tiles-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;
        margin-left: 2rem;
        min-width: 1.185rem;
        height: 11rem;
        background: #ffffff;
        border-radius: 0.3125rem;
        box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease-in-out;
        }

        .cohort-tiles-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .cohort-tile-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-family: $font-family-lato;
            font-style: normal;
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.375rem;
            letter-spacing: 0.0125rem;
            color: #262626;

            .cohort-tile-options {
              display: flex;
              align-items: center;
              .pubnub-switch-root {
                color: #0e1555;
              }
            }
          }
          .cohort-tile-date-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 1.3125rem;
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            color: #788188;
            .cohort-tile-date {
              &-start,
              &-end {
                display: flex;
                .label {
                  font-family: $font-family-lato;
                  font-weight: 700;
                }
              }
              &-start {
                .label {
                  margin-left: 0.75rem;
                }
              }
              &-end {
                .label {
                  margin-left: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

//cohort filter styles

.filter-main-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
  justify-content: space-between;
  padding: 0 1.25rem 0 0;
}
.filter-model-main{
  display: flex;
  flex-direction: column;
  //align-items: center;
}
 .form-control-root {
  min-width: 15.5rem;
}
.form-label{
font-family: "Nunito";
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
min-width: 200px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.25px;
padding: 1rem;
color: #121212;
}
.sort-by-wrapper{
  display: flex;
  flex-direction: row;
  //justify-content: space-around;
}
.program-sort-wrapper{
  display: flex;
  flex-direction: row;
}
.published-wrapper{
  display: flex;
  flex-direction: row;
}
.radio-group-wrapper {
  display: flex;
  flex-direction: row;
}
.button-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
}
.date-wrapper{
  display: flex;
  flex-direction: row;
}

/* Common styles for both buttons */
.btn {
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: $font-family-lato;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1rem;
  letter-spacing: 0.01em;
  line-height: 1.0625rem;
  text-align: center;
  width: 10rem;
  border-radius: 6px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.save-btn {
  background-color: #fac30f;
  color: #fff;
}

.cancel-btn {
  background-color: #a8a8a8;
  color: #fff;
}

.save-btn:hover, .save-btn:focus {
  background-color: #e6b30e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cancel-btn:hover, .cancel-btn:focus {
  background-color: #999999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
