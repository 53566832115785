.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .close-icon {
    cursor: pointer;
  }
}

.form-wrapper {
  height: 564px;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  .input-wrapper { // Generalized wrapper for all input fields
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #262626;
    }

    .input-field { // Generalized input field style
      width: 100%;
      margin-top: 10px;
    }

    .char-length { // Character length for text areas
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .update-button {
    background: #142297;
    border-radius: 8px;
    width: 80px;
    height: 48px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    margin-left: 20px;
  }
  .cancel-button {
    background: #ffffff;
    border: 1px solid #142297;
    border-radius: 6px;
    width: 80px;
    height: 48px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #142297;
  }
}
