@import "../../../../Stylesheets/Utils/fonts.scss";
@import "../../../../Stylesheets/Utils/colors.scss";

.button-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem;

  .cancel-btn {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding: 1.25rem 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
  }

  .save-btn {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding: 1.25rem 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
  }
}

.form-wrapper {
  border-radius: 0.625rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-header {
    color: #262626;
    font-family: Lato;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .content-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal-grade {
        color: #262626;
        font-family: Lato;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .form-control {
        display: flex;
        margin: 1rem;

        .text-field {
          display: flex;
          width: 24rem;
          height: 2.875rem;
          padding: 0.625rem 0.3125rem 0.6875rem 2.125rem;
          justify-content: flex-end;
          align-items: center;
          flex-shrink: 0;
        }
      }

      .credits-left {
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #03a10a;
      }
    }
  }
}

.grade-or-resubmit-form-div {
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .heading {
    color: #262626;
    font-family: Lato;
    font-size: 1.325rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .radio-group-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }

  .button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;

    .cancel-btn {
      display: flex;
      width: 10rem;
      height: 2.5rem;
      justify-content: center;
      align-items: center;
    }

    .save-btn {
      display: flex;
      width: 10rem;
      height: 2.5rem;
      justify-content: center;
      align-items: center;
    }
  }
}

.pass-fail-form-component {
  display: flex;
  flex-direction: column;

  .heading {
    color: #262626;
    font-family: Lato;
    font-size: 1.325rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  .radio-group-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .pass-div {
      background-color: rgba(3, 161, 10, 0.2);
      padding: 0.2rem;
    }

    .fail-div {
      margin-top: 0.5rem;
      padding: 0.2rem;
      background-color: rgba(255, 44, 85, 0.2);
    }
  }

  .button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 6rem;

    .cancel-btn {
      display: flex;
      width: 10rem;
      height: 2.5rem;
      justify-content: center;
      align-items: center;
    }

    .save-btn {
      display: flex;
      width: 10rem;
      height: 2.5rem;
      justify-content: center;
      align-items: center;
    }
  }
}

.container-root {
  width: calc(100% - 4rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

  .student-questionnaire-feedback-menu-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .preview-section {
    display: flex;
    flex-direction: row;
    height: 140px;
    background: #004aad;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .option {
      height: 86px;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      border: 1px solid #ededed;
      border-radius: 10px;
      width: 15%;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .heading {
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-left: 1rem;
        color: #262626;
      }

      .value {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        margin-left: 1rem;
        color: #0e1555;
      }
    }
  }

  .questions-container {
    display: flex;
    flex-direction: column;

    .review-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 1rem;

      .review-button {
        display: flex;
        width: 6.3125rem;
        height: 1.9375rem;
        // padding: 1.25rem 2.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        flex-shrink: 0;
        border-radius: 0.3125rem;
        background: #142297;
        color: #ffffff;
        cursor: pointer;
      }

      .invisible {
        visibility: hidden;
      }

    }

    .question {
      display: flex;
      flex-direction: column;
      padding: 1.875rem;
      justify-content: space-evenly;

      .span-question {
        display: flex;

        .title {
          display: flex;
          width: 100%;
        }

        .title-wrapper {
          width: unset;
        }

        .title,
        .title-wrapper,
        .input {
          font-family: $font-family-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.375rem;
          letter-spacing: 0.0125rem;
          color: #000000;
        }

        .question-title {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-left: 0.25rem;
        }
      }

      .answers {
        display: flex;
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        padding: 0.625rem;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        align-items: center;
        color: #4d4d4d;
        margin-top: 0.875rem;
        background: #f2f2f2;
        border-radius: 0.5rem;
      }

      .no-submission {
        display: flex;
        justify-content: center;
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        padding: 0.625rem;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        align-items: center;
        color: #4d4d4d;
        margin-top: 0.875rem;
        background: #f2f2f2;
        border-radius: 0.5rem;
      }

      .feedback-wrapper {
        display: flex;
        flex-direction: column;

        .give-view-feedback-accordion {
          display: flex;
          flex-direction: column;
          margin: 0 1.875rem;

          .give-view-feedback-label {
            cursor: pointer;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.375rem;
            display: flex;
            align-items: center;
            color: #299ef3;
            margin-top: 0.6875rem;

            .icon-class-up,
            .icon-class-down {
              width: 1.5rem;
              height: 1rem;
            }
          }

          .button-root {
            margin-top: 0.5rem;
            width: 8.5rem;
            height: 2.75rem;
            background-color: rgba(25, 118, 210, 0.1);
            color: #0e1555;
            padding: 0 1.25rem;
            box-shadow: unset;
            border-radius: 0.25rem;
            align-self: flex-end;

            &:hover {
              background-color: rgba(25, 118, 210, 0.1);
            }
          }
        }
      }
    }

    .button-root-bottom {
      margin-bottom: 0.625rem;
      width: 14.5rem;
      height: 2.75rem;
      background-color: rgba(25, 118, 210, 0.1);
      color: #0e1555;
      padding: 0 1.25rem;
      box-shadow: unset;
      border-radius: 0.25rem;
      align-self: flex-end;
      margin-right: 1.875rem;

      &:hover {
        background-color: rgba(25, 118, 210, 0.1);
      }
    }
  }

  .no-data-found {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    font-family: "Lato";
    font-weight: 500;
    line-height: 1.375rem;
  }

  .module-section-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1.5rem 0;
    margin-bottom: 2rem;

    .nav-prev,
    .nav-next {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .grade-display {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid #c5c5c5;
    background: #f7f7f7;
    height: 40px;
    width: 40px;
  }

  .additional-details {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;

    .heading {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;

      letter-spacing: 0.005em;

      color: #0e1555;
    }

    .download-button {
      height: 40px;
      width: 20%;
      margin: 1rem;
      background: rgba(3, 162, 10, 0.1);
      border-radius: 5px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      border: 2px #03a20a;
      display: flex;
      align-items: center;
      text-align: center;

      color: #03a20a;
    }

    .video-interview-section {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      width: 50%;
      justify-content: space-between;

      .label {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;

        display: flex;
        align-items: center;
        letter-spacing: 0.5px;

        color: #000000;
      }

      .video-interview-link {
        background: #ffffff;
        /* Grey 3 */
        height: 50px;
        width: 30%;

        border: 1px solid #9fa1bb;
        border-radius: 3px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        letter-spacing: -0.25px;
        justify-content: center;
        color: #a5a6b6;
      }
    }
  }
}