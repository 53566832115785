@import '../../../Utils/colors.scss';
.row-wise-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 0;
  .image-wrapper {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 50%;
    .image-preview-placeholder {
      width: 113px;
      height: 113px;
      background: #f6f6f6;
      border: 1px solid #000000;
      border-radius: 71px;
      object-fit: contain;
    }
    .image-upload-option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 16px;
      .helper-text {
        display: flex;
        width: 150px;
        height: 32px;
        padding-bottom: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #595959;
      }
      .upload-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 131px;
        height: 37px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #004aad;
        background: #ffffff;
        border: 1.5px solid #004aad;
        border-radius: 6px;
        cursor: pointer;
      }
      .no-image-error {
        color: #d8000c;
        padding-top: 0.25rem;
      }
    }
  }
  .live-session-modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    padding-right: 20px;

    .start-end-date-wrapper {
      display: flex;
      flex-direction: row;

      .input-fields-labels {
        padding: 0;
        &:nth-child(1) {
          padding-right: 1rem;
        }
        .date-picker-comp {
          width: 12.5rem;
        }
        .form-control-root {
          min-width: 12.5rem;
        }
      }
    }
    .duration-wrapper {
      display: flex;
      flex-direction: column;

      .form-wrapper {
        display: flex;
        flex-direction: row;
      }
    }

    .input-fields-labels {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.75rem 0;
      flex-wrap: wrap;

      > span {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: #262626;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
      }

      .mandatory {
        color: $color-red;
        vertical-align: middle;
      }

      .form-control-root {
        margin-left: 0;
        height: 3.125rem;
        min-width: 26.5rem;
        background: $color-white;
        .select-tag-root {
          max-width: 26.5rem;
          .chips-container {
            overflow: hidden;
            overflow-x: auto;
          }
        }
        .select-root {
          width: 310px;
          height: 100%;
          background: $color-white;
        }
      }

      .date-picker-comp {
        margin-left: 0;
        width: 19.375rem;
        height: 3.125rem;
        background: $color-white;
        border-radius: 0.1875rem;
        .notched-override {
          border: none;
          border-radius: 0.1875rem;
        }
      }
    }
  }
}
.input-fields-labels-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  .input-fields-labels-rec,
  .input-fields-labels-slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1.5rem;

    > span {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      color: #262626;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
    }

    .mandatory {
      color: $color-red;
      vertical-align: middle;
    }

    .form-control-root {
      margin-left: 0;
      height: 3.125rem;
      min-width: 19.375rem;
      background: $color-white;
      margin-right: 0.75rem;
      .select-root {
        width: 310px;
        height: 100%;
        background: $color-white;
      }
    }
  }
}
.module-name {
  color: #262626;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  margin: 1.5rem;
  margin-top: 0;
  .mandatory {
    color: $color-red;
    vertical-align: middle;
  }
}
.module-time-slots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 3rem);
  margin: 1.5rem;
  margin-top: 0;
  .slot-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.375rem;
    border: 0.0625rem solid #ebebeb;
    background: #f7f7f7;
    width: 22.5625rem;
    height: 2.9375rem;
    margin: 0.125rem;
    padding: 0.3125rem;

    .selected-module-name {
      display: block;
      width: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .set-time-slot {
      display: flex;
      align-items: center;
      flex-direction: row;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 10px;
      line-height: 17px;
      color: #004aad;
      background: #ffffff;
      border: 1.5px solid #004aad;
      border-radius: 6px;
      cursor: pointer;
    }
    .selected-time-slot-wrapper {
      display: flex;
      .selected-date-time-slot {
        display: inline-flex;
        padding: 6px 10px;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        background: #142297;
        color: #ffffff;
        font-family: "Lato";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        .selected-start-slot,
        .selected-end-slot {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .edit-time-slot {
        display: flex;
        cursor: pointer;
      }
    }
  }
}
.details-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  .live-session-details-wrapper {
    display: flex;
    width: calc(100% - 3rem);
    flex-wrap: wrap;
    margin: 1.5rem;
    .live-session-details-header {
      color: #262626;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      width: 100%;
      .mandatory {
        color: $color-red;
        vertical-align: middle;
      }
    }

    .title-desc-comp-container {
      display: flex;
      width: 100%;
      margin-top: 1.125rem;
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .cancel-button {
      background: #ffffff;
      border: 1px solid #142297;
      border-radius: 6px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #142297;
    }
    .submit-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      margin-left: 20px;
      color: #ffffff;
      border-radius: 6px;
      &.disabled {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        width: 160px;
        height: 39px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        margin-left: 20px;
        color: #ffffff;
        cursor: not-allowed;
        background: grey;
        border-radius: 6px;
      }
    }
  }
}
