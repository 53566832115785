.main-div {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    background-image: url("../../../../Assets/Images/banner.png");
    height: 90px;
    .setting-header {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 130%;
      display: flex;
      align-items: center;
      letter-spacing: 0.008em;
      color: #ffffff;
      margin: 3rem;
    }
  }

  .settings-wrapper {
    display: flex;
    flex-direction: row;
    background-color: #f7f7fc;
    height: 1000px;
    .menu-options {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      width: 20%;
      max-width: 322px;
      .options {
        height: 50px;
        display: flex;
        flex-direction: row;
        /* Default/White */
        font-family: "Nunito";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
        margin: 1rem;
        color: #0e1555;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease-in-out;
        }
        .label {
          margin: 1rem;
        }
      }
    }
    .menu-display {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 100%;
      .help-form-wrapper {
        display: flex;
        flex-direction: column;
        margin: 4rem;
        width: 100%;
        .menu-header {
          font-family: "Nunito";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 55px;
          color: #192547;
          display: flex;
          flex-direction: column;
        }
        .menu-sub-header {
          font-family: "Nunito";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #141414;
        }
        .form-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          .help-banner {
            margin-top: 5%;
            .banner {
              width: 100%;
              height: 288px;
            }
          }
        }
      }
    }
  }
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  .modal-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #000000;
  }
  .modal-sub-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #464545;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    justify-content: space-between;
    .cancel-btn {
      width: 160px;
      height: 40px;

      background: #a8a8a8;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }
    .save-btn {
      width: 160px;
      height: 40px;

      background: #fac30f;
      border-radius: 6px;

      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }
  }
}
