@import '../Utils//colors.scss';


.password-reset-body {
    width: 30rem;
    height: 37.5rem;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .content {
        display: flex;
        flex-direction: column;
        width: calc(100% - 3.125rem);
        margin: 0 auto;
        margin-top: 3.75rem;

        .name-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .form-control {
                width: 46%;
            }
        }

        .form-control {
            margin-bottom: 1.25rem;

            .input-label {
                color: $color-cool-grey;
            }

            .notched-outline-root {
                border-color: $color-primary-blue;
                border-radius: 0.625rem;
            }

            .user-input-field {
                padding-left: 0.625rem;
                -webkit-box-shadow: 0 0 0 1.875rem #F4F4F4 inset !important;
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type=number] {
                    -moz-appearance: textfield;
                }
            }

            .outline-root {
                .user-icon {
                    cursor: default;
                }
                .icon-button-root {
                    margin-right: -0.3125rem;
                }
            }

            .MuiFormControl-root {
                .MuiOutlinedInput-root {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: $color-primary-blue !important;
                        border-radius: 0.625rem !important;
                    }
                }
            }
        }

        .button-root {
            background-color: $color-dark-blue;
            height: 3.125rem;
            border-radius: 0.625rem;

            .arrow-icon {
                background-color: rgba(255, 255, 255, 0.31);
                border-radius: 3.125rem;
                padding: 0.3125rem;
                font-size: 1.125rem;
                position: absolute;
                right: 1.25rem;
                top: 0.625rem;
            }
        }

        .breaking-label {
            color: $color-cool-grey;
            margin: 0 auto;
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
            position: relative;
            text-align: center;
            width: 100%;

            &::after {
                content: "";
                display: block;
                width: 40%;
                height: 0.0625rem;
                background: $color-light-grey;
                right: 0;
                top: 50%;
                position: absolute;
            }

            &::before {
                content: "";
                display: block;
                width: 40%;
                height: 0.0625rem;
                background: $color-light-grey;
                left: 0;
                top: 50%;
                position: absolute;
            }
        }

        .social-login-container {
            // margin: 0 auto;

            .google-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-shadow: 0rem 0rem 0.375rem 0.125rem $color-light-grey;
                border-radius: 0.625rem;
                .google-img-btn {
                    padding: 0.9375rem;
                    border-radius: 3.125rem;
                    height: 1.25rem;
                    object-fit: contain;
                }
            }
        }

        .footer-container {
            margin: 0 auto;
            padding: 2.5rem;
            white-space: nowrap;
            .auth-modal-toggle-btn {
                color: $color-primary-blue;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .password-reset-body {
        width: 100%;
    }
}