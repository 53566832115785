@import "../Utils/colors.scss";

.under-dev-container {
  display: flex;
  justify-content: center;
  height: 100%;
  .page-build-img {
    width: 31.25rem;
    object-fit: contain;
  }
}
