.main-div {
  display: flex;
  flex-direction: column;
  .label {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .org-toggle-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .student-toggle-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
