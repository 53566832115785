@import "../Utils/colors.scss";

.gif-loader {
  background: url("../../Assets/loading.gif") no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  background-color: #fffffffc;
}
.home-container {
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    position: relative;

    .left,
    .ri-styles-left,
    .plain-bg,
    .asa-bg {
      position: absolute;
      z-index: 100;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      padding-bottom: 0rem;
      padding: 0;
      display: flex;
      justify-content: center;

      .text-container,
      .ri-styles-text-container,
      .vl-styles-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem;
        text-align: center;

        .title {
          font-size: 2.25rem;
          color: $color-white;
        }

        .sub-title {
          font-size: 1.5rem;
          padding-top: 0.625rem;
          padding-bottom: 0.625rem;
          color: $color-light-grey;
        }
      }
      .ri-styles-text-container {
        width: 50%;
      }
      .vl-styles-text-container {
        .title {
          color: #162955;
          font-family: "Nunito Sans", Sans-serif;
          font-size: 65px;
          font-weight: bold;
          line-height: 1.1em;
        }
        .sub-title {
          color: #333333;
          font-family: "Nunito Sans", Sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5em;
        }
      }
    }
    .asa-bg {
      background: rgba(0, 0, 0, 0.1);
      .text-container {
        padding-top: 10rem;
        .title {
          color: #000;
          font-weight: 600;
        }
        .sub-title {
          color: #000;
        }
      }
    }
    .plain-bg {
      background-color: white;
      border-style: solid;
      border-width: 0px 0px 0px 0px;
      border-color: #dedede;
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
      border-bottom: 0.9px;
      border-bottom-style: ridge;
    }
    .ri-styles-left {
      justify-content: flex-start;
    }

    .right,
    .ri-styles-right,
    .asa-right {
      height: 18.75rem;
      width: 100%;
      position: relative;

      &:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background: -moz-linear-gradient(
          left,
          rgb(255 255 255) 0%,
          rgba(255, 255, 255, 0) 49%,
          rgb(255 255 255 / 5%) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgb(255 255 255) 0%,
          rgba(255, 255, 255, 0) 49%,
          rgb(255 255 255 / 5%) 100%
        );
      }

      .banner {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .ri-styles-right {
      height: 48.75rem;
    }
    .asa-right {
      height: auto;
      max-width: 100%;
      &:before {
        position: unset;
      }
      .banner {
        height: auto;
        max-width: 100%;
        object-fit: unset;
      }
    }
  }

  .cohorts-container {
    background-color: $color-white;

    .category-container {
      padding-bottom: 2.5rem;

      .category-header {
        padding: 2.5rem;
        padding-bottom: 1.5rem;

        .header {
          display: block;
          font-size: 1.875rem;
          font-weight: bold;
          color: $color-primary-blue;
          padding-bottom: 0.9375rem;
        }

        .desc {
          color: $color-light-black;
        }
      }

      .cohorts-main-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;

        .cohort-cards-container {
          margin-top: 2.5rem;
          margin-left: 2.5rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .cohort-card {
            cursor: pointer;
            position: relative;
            box-shadow: 0rem 0rem 1.25rem 0rem #cbcbcb;
            border-radius: 0.625rem;
            background-color: white;
            width: 25rem;
            padding: 1.25rem;
            margin: 1.25rem;

            .content {
              display: flex;
              flex-direction: column;

              .creator {
                padding: 0.625rem 0;
                text-transform: capitalize;
              }

              .title {
                font-size: 1.5rem;
                color: $color-primary-blue;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 80%;
                height: 3.75rem;
              }

              .sub {
                display: -webkit-box;
                max-width: 100%;
                height: 4.375rem;
                font-size: 1rem;
                line-height: 1.375rem;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 1.25rem 0;
                color: $color-light-grey-2;
              }

              .duration {
                .duration-text {
                  padding: 0 0.625rem;
                }
              }
            }

            .date-container {
              position: absolute;
              top: 1.25rem;
              right: 1.25rem;
              height: 3.75rem;
              width: 4.0625rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 0.0625rem solid #b5b5b5;
              border-radius: 0.3125rem;
              font-weight: bold;
            }

            .action-container {
              display: flex;
              padding: 1.25rem 0.625rem;
              padding-right: 0;
              justify-content: flex-end;

              .button-root {
                background-color: $color-dark-blue;
                height: 3.125rem;
                border-radius: 0.625rem;

                .arrow-icon {
                  background-color: rgba(255, 255, 255, 0.31);
                  border-radius: 3.125rem;
                  padding: 0.3125rem;
                  font-size: 1.125rem;
                  right: 1.25rem;
                  top: 0.625rem;
                }
              }

              .status-container {
                color: orange;
                display: flex;
                align-items: center;
                margin-top: 1.25rem;

                &.rejected {
                  color: $color-red;
                }

                .cohort-cards-container {
                  margin-top: 2.5rem;
                  margin-left: 2.5rem;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;

                  .cohort-card {
                    cursor: pointer;
                    position: relative;
                    box-shadow: 0rem 0rem 1.25rem 0rem #cbcbcb;
                    border-radius: 0.625rem;
                    background-color: white;
                    width: 25rem;
                    padding: 1.25rem;
                    margin: 1.25rem;

                    .content {
                      display: flex;
                      flex-direction: column;

                      .creator {
                        padding: 0.625rem 0;
                        text-transform: capitalize;
                      }

                      .title {
                        font-size: 1.5rem;
                        color: $color-primary-blue;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 80%;
                        height: 3.75rem;
                      }

                      .sub {
                        display: -webkit-box;
                        max-width: 100%;
                        height: 4.375rem;
                        font-size: 1rem;
                        line-height: 1.375rem;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 1.25rem 0;
                        color: $color-light-grey-2;
                      }

                      .duration {
                        .duration-text {
                          padding: 0 0.625rem;
                        }
                      }
                    }

                    .date-container {
                      position: absolute;
                      top: 1.25rem;
                      right: 1.25rem;
                      height: 3.75rem;
                      width: 4.0625rem;
                      display: none;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border: 0.0625rem solid #b5b5b5;
                      border-radius: 0.3125rem;
                      font-weight: bold;
                    }

                    .action-container {
                      display: flex;
                      padding: 1.25rem 0.625rem;
                      justify-content: flex-end;

                      .button-root {
                        background-color: $color-dark-blue;
                        height: 3.125rem;
                        border-radius: 0.625rem;

                        .arrow-icon {
                          background-color: rgba(255, 255, 255, 0.31);
                          border-radius: 3.125rem;
                          padding: 0.3125rem;
                          font-size: 1.125rem;
                          right: 1.25rem;
                          top: 0.625rem;
                        }
                      }

                      .status-container {
                        color: orange;
                        display: flex;
                        align-items: center;
                        margin-top: 1.25rem;

                        &.rejected {
                          color: $color-red;
                        }

                        &.applied {
                          color: green;
                        }
                        .text {
                          padding-left: 0.3125rem;
                        }
                      }
                    }
                  }
                }
                .text {
                  padding-left: 0.3125rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .home-container {
    .cohorts-container {
      .category-container {
        .cohort-cards-container {
          width: 100%;
          padding: 0 1.25rem;
          margin: 0 !important;
          .cohort-card {
            width: calc(100%) !important;
            .content {
              .title {
                width: 75% !important;
                font-size: 0.9rem !important;
                height: 2.75rem !important;
              }
              .sub {
                font-size: 0.8rem !important;
              }
            }
            .date-container {
              font-size: 0.8rem;
              height: 2.75rem !important;
              width: 3.0625rem !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .home-container {
    .ri-styles-left {
      .ri-styles-text-container {
        justify-content: center !important;
        text-align: left !important;
        > span {
          font-size: inherit !important;
        }
      }
    }
    .asa-right {
      height: 18.75rem !important;
    }
    .ri-styles-right {
      height: 18.75rem !important;
    }
    .plain-bg {
      .vl-styles-text-container {
        .title {
          font-size: 38px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .home-container {
    .ri-styles-left {
      .ri-styles-text-container {
        justify-content: flex-start !important;
        text-align: left !important;
        > span {
          font-size: inherit !important;
        }
      }
    }
    .asa-right {
      height: 18.75rem !important;
    }
    .ri-styles-right {
      height: 18.75rem !important;
    }
    .plain-bg {
      .vl-styles-text-container {
        .title {
          font-size: 38px !important;
        }
      }
    }
  }
}
