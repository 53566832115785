.modal-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .close-icon {
    cursor: pointer;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px; // Added for consistent spacing

  .MuiTextField-root { // Styles for all TextField components
    width: 100%;
    .MuiOutlinedInput-root {
      height: 56px; // Adjust height if necessary
    }
  }

  .MuiPhoneNumber-root { // Specific styles for the phone number field
    width: 100%;
  }

  .bio-wrapper {
    .MuiOutlinedInput-root { // Adjustments for the bio input
      height: 100px;
      overflow-y: auto;
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px; // Added margin for spacing

  .update-button, .cancel-button {
    width: 100px;
    height: 48px;
    margin: 0 10px;
    font-family: "Lato";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .update-button {
    background: #142297;
    color: white;
    &:disabled { // Styles for disabled state
      background: grey;
      cursor: not-allowed;
    }
  }

  .cancel-button {
    background: #ffffff;
    border: 1px solid #142297;
    color: #142297;
  }
}
