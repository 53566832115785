$color-dark-blue: #0e1555;
$color-primary-blue: #0e1555;
$color-secondary-blue: #142297;

$color-cool-grey: #8d8da7;
$color-dark-grey: #7a7a7a;
$color-light-grey: #d3d3d3;
$color-light-grey-2: #7c7c7c;

$color-orange-medium: #ff8000;
$color-dark-orange: #ec441e;
$color-light-blue: #f8faff;
$color-light-black: #4d4d4d;
$color-medium-black: #11142d;

$color-white: #ffffff;
$color-black: #000000;
$color-red: #ff0000;

$bg-color-yellow: linear-gradient(154.49deg, #ffd085 6.61%, #ffaf2e 89.72%);

$color-button-light-blue: #2e53dc;

//styles for titledescription module
$desc-header-color: #1b2850;
$desc-header-color: #333333;
$form-desc-root-color: #f7f7f7;
//@include set-color("red");

$arrow-icon-color: rgba(255, 255, 255, 0.31);
$cohort-header-container-color: linear-gradient(107.28deg,
  #0e1555 38.14%,
  #046082 98.54%);
$color-light-grey: lightgray;
$color-orange: orange;
$color-green: green;
$color-red: red;
$course-details-label-color: #373a3c;
$color-gray: gray;
$application-header-color: #e2e7ee;
$header-cell-color: #595959;
$modules-title-wrapper-color: #f8f8f8;
$box-shadow-color: #acacac;
$modules-title-color: #121212;
$modules-disabled-color: rgb(224 224 224);
$create-module-color: rgba(25, 118, 210, 0.1);
$cohort-box-shadow-color: rgba(0, 0, 0, 0.25);
$cohort-photo-video-upload-color: #e9ebff;
$cohort-upload-label: #2f3438;
$separator-border-color: #c5c5c5;
$overview-section-color: rgba(100, 100, 111, 0.2);
$overview-section-title-color: #090909;
$discuss-with-peers: linear-gradient(134.72deg, #00eaf8 0%, #6d42ef 101.5%);
$additional-resource-color: linear-gradient(135deg, #fed992 0%, #eb5556 100%);
$create-module-button-color: rgba(25, 118, 210, 0.1);
$create-module-hover-color: rgba(25, 118, 210, 0.1);
$very-light-gray: #dedede;
$tick-icon-color: #979797;
$color-light-green: #03a20a;
$floating-nav-color: rgba(0, 0, 0, 0.5);
$add-question-button: rgba(25, 118, 210, 0.2);
$greyish-blue: #252733;
$color-light-blue: #299ef3;
$selected-item-color: #cadcff;
$box-shadow-right-color: rgb(0 0 0 / 25%);
$grey-button-color: rgb(111, 111, 111);
$question-type-color:#004AAD;
$question-type-edit-icon:#9FA1BB;
$question-new-add-color:#FAC30F