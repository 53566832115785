.student-profile-body {
  margin: 123px auto 0 auto;
  margin-left: 100px;
  margin-right: 100px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}
