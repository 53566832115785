.main-div {
  display: flex;
  flex-direction: column;
  .label-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .div-label {
      width: 244px;
      height: 65px;
      left: 93px;
      top: 398px;
      position: relative;
      top: -32px;
      left: 0px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.005em;

      color: #ffffff;
      background: #0e1555;
      border-radius: 0px 10px 10px 0px;
    }
    .edit-option {
      padding: 18px;
      cursor: pointer;
    }
  }
  .bootcamp-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .bootcamp-details {
      display: flex;
      flex-direction: row;
      margin: 1rem 2rem;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      align-items: center;
      letter-spacing: 0.005em;
      color: #000000;
      .label {
        display: flex;
        width: 30%;
      }
      .value {
        display: flex;
        width: 70%;
      }
    }
  }
}
