@import "../../../Utils/colors.scss";
@import "../../../Utils/fonts.scss";

.container-root {
  width: calc(100% - 2rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);
  position: relative;

  .create-session-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: $color-black;
  }
  .create-live-session-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0.625rem;
    right: 0.625rem;
    .create-module-button {
      background-color: $create-module-button-color;
      color: $color-primary-blue;
      height: 3.125rem;
      border-radius: 0.625rem;
      padding: 0 1.25rem;
      box-shadow: unset;

      &:hover {
        background-color: $create-module-hover-color;
      }

      .arrow-icon {
        background-color: $arrow-icon-color;
        border-radius: 3.125rem;
        padding: 0.3125rem;
        font-size: 1.125rem;
        right: 1.25rem;
        top: 0.625rem;
      }
    }
  }
  .live-sessions-list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 20px;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 13rem);

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: fit-content;
      cursor: pointer;
      box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
      border-radius: 0px 10px 10px 10px;
      margin-bottom: 2rem;
      margin-right: 6rem;
      margin-left: 2rem;
      .edit-delete-section {
        display: none;
      }
      &:hover {
        transform: scale(1.08);
        transition: transform 0.2s ease-in-out;
        .edit-delete-section {
          width: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .card-number-indicator {
          width: 30%;
          box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
          border-radius: 10px 10px 10px 0px;
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 11px;
          color: #ffffff;
          padding: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .cards-wrapper {
        display: flex;
        flex-direction: column;
        width: 514px;
        height: 265px;
        padding: 22px 20px;
        background: #ffffff;
        box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.05);
        border-radius: 0px 10px 10px 10px;

        .image-spkname-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 66px;
            object-fit: contain;
          }
          .spkname-wrapper {
            display: flex;
            flex-direction: column;
            font-family: "Lato";
            font-style: normal;
            color: #262626;
            margin-left: 1rem;
            .spkname {
              font-weight: 700;
              font-size: 15px;
              line-height: 18px;
            }
            .time-range {
              font-weight: 500;
              font-size: 11px;
              line-height: 20px;
            }
          }
        }
        .event-overview {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #262626;
          margin: 2rem;
          height: 80px;
        }
        .separator {
          border: 1px solid #e4e4e4;
        }
        .event-link-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          a:link {
            background-color: transparent;
            text-decoration-color: #299ef3;
          }
          .label {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;

            display: flex;
            align-items: center;

            color: #262626;
          }
          .value {
            color: #299ef3;
            padding-left: 5px;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .recurring-wrapper {
        }
        .slots-wrapper {
        }
      }
    }
  }
}
.modal-container-wrapper {
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -2.5rem;
    padding: 1.25rem 1.625rem;
    background: #142297;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
}
