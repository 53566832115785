@import "../../../Utils/colors.scss";
@import "../../../Utils/fonts.scss";

.container-root {
  width: calc(100% - 2rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);
  position: relative;

  .create-session-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: $color-black;
  }
  .information-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .information-box {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      box-shadow: 0px 0px 38px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      height: calc(100vh - 15rem);
      width: 95%;
      .left-side-wrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        .speaker-wrapper {
          display: flex;
          flex-direction: row;
          margin: 1rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 66px;
            object-fit: contain;
          }
          .speaker-name {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            margin-left: 10px;
            display: flex;
            align-items: center;

            color: #262626;
          }
        }
        .event-detail-wrapper {
          display: flex;
          flex-direction: column;
          margin-right: 1rem;
          .row-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            .left-label {
              display: flex;
              justify-content: flex-start;
              flex-basis: 40%;
              width: 100%;
              font-family: "Lato";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.2px;
              margin: 1rem;
              color: #262626;
            }
            .right-value {
              display: flex;
              justify-content: flex-start;
              flex-basis: 60%;
              width: 100%;
              font-family: "Lato";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              margin-left: 1rem;
              color: #262626;
            }
            .link-value {
              font-family: "Lato";
              font-style: italic;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              width: 60%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              text-decoration-line: underline;
              cursor: pointer;
              color: #299ef3;
            }
          }
        }
      }
      .divider {
        border: 1px solid #e4e4e4;
      }
      .right-side-info {
        display: flex;
        flex-direction: column;
        width: 70%;
        overflow: auto;
        .speaker-wrapper {
          display: flex;
          flex-direction: column;
          margin: 2rem;
          .heading {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;

            display: flex;
            align-items: center;

            color: #262626;
          }
          .content {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            margin: 1rem 0;
            color: #262626;
          }
        }
        .event-wrapper {
          display: flex;
          flex-direction: column;
          margin: 2rem;
          .heading {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;

            display: flex;
            align-items: center;

            color: #262626;
          }
          .content {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            margin: 1rem 0;
            color: #262626;
          }
        }
      }
    }
  }
  .live-session-slot-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    .heading {
      display: flex;
      justify-content: flex-start;
      flex-basis: 40%;
      width: 100%;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      letter-spacing: 0.0125rem;
      margin: 1rem;
      padding-left: 2rem;
      color: #262626;
    }
    .slot-wrapper {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      min-height: 3.1875rem;
      width: 100%;
      max-height: 19.5rem;
      margin-bottom: 1rem;
      overflow: scroll;

      .no-slot-selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.1875rem;
        letter-spacing: 0.0125rem;
        margin: 1rem;
        color: #262626;
      }
      .slot-box-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 0.3125rem 0;
        &-left {
          display: flex;
          flex-basis: 50%;
          justify-content: flex-start;
          overflow: hidden;
          white-space: nowrap;
          &-module {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.1875rem;
            letter-spacing: 0.0125rem;
            margin: 1rem;
            color: #262626;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 5px;
            width: 50%;
          }
        }
        &-right {
          display: flex;
          flex-basis: 40%;
          justify-content: flex-start;
          &-wrapper {
            display: inline-flex;
            padding: 6px 10px;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            background: #142297;
            color: #ffffff;
            font-family: "Lato";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &-start-time,
            &-end-time {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
/* For Firefox */
html {
  scrollbar-width: none;
}
/* For IE and Edge */
html {
  -ms-overflow-style: none;
}