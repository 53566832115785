@import "../../../Utils/colors.scss";

.container-root {
  width: calc(100% - 2rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: $color-white;
  box-shadow: 0 0.0625rem 0.25rem $cohort-box-shadow-color;
  position: relative;

  .module-wrap-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 13rem);
    position: relative;
    .modules-tiles-wrapper {
      background: $modules-title-wrapper-color;
      position: relative;
      border-radius: 0.3125rem;
      margin: 2.5rem;
      box-shadow: none;
      box-shadow: 0rem 0.25rem 0.1875rem 0rem $box-shadow-color;
      transition: transform 0.2s ease-in-out;
      overflow: unset;

      &:hover {
        transform: scale(1.005);
        transition: transform 0.2s ease-in-out;
      }

      .module-options-container {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .edit-icon {
          padding: 0.625rem;
          cursor: pointer;
        }

        .pubnub-switch-root {
          color: $color-primary-blue;
        }
        .drag-icon {
          cursor: move;
        }

        .pubnub-switch-root-inactive {
          color: $color-gray;
        }
      }

      .modules-tiles-content {
        .modules-content-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;

          .modules-header {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: 0.1875rem;
            text-transform: uppercase;
            color: $color-secondary-blue;
          }

          .modules-title {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.5rem;
            letter-spacing: 0.0125rem;
            color: $modules-title-color;
            padding-top: 1.125rem;
            padding-bottom: 0.625rem;
          }

          .modules-description {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 150%;
            letter-spacing: 0.0125rem;
            color: $color-light-black;
          }
          .grading-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-basis: 100%;
            flex-wrap: wrap;
            .module-options-wrapper {
              display: flex;
              flex-direction: row;
              flex-basis: 70%;
              .individual-option {
                margin-left: 10px;
                box-sizing: border-box;
                margin-top: 10px;
                width: 178.73px;
                height: 39px;
                text-align: center;
                background: #ffffff;
                border: 1px solid #e6e6e6;
                border-radius: 5px;
                font-family: "Nunito";
                font-style: normal;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000000;
                position: relative;
                > span {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  padding: 0 5px;
                  &[data-tooltip]:hover::before,
                  &[data-tooltip]:hover::after {
                    content: attr(data-tooltip);
                    position: absolute;
                    padding: 0.5rem;
                    background-color: #333;
                    color: #fff;
                    border-radius: 5px;
                    font-size: 14px;
                    white-space: nowrap;
                  }

                  &[data-tooltip]:hover::before {
                    top: calc(100% + 5px);
                    left: 50%;
                    transform: translateX(-50%);
                  }

                  &[data-tooltip]:hover::after {
                    display: none;
                  }
                }
              }
            }
            .skills-set {
              span {
                font-weight: 600;
              }
              box-sizing: border-box;
              margin-left: 0.625rem;
              margin-top: 0.625rem;
              padding: 0.625rem;
              width: 100%;
              min-height: 2.4375rem;
              background: #ffffff;
              border: 1px solid #e6e6e6;
              border-radius: 0.3125rem;
              font-family: "Nunito";
              font-style: normal;
              font-weight: 500;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: #000000;
              position: relative;
            }
            .credits-used {
              align-items: center;
              color: #e6e6e6;
              width: 120px;
              height: 31px;
              display: flex;
              font-family: "Lato";
              font-style: normal;
              font-weight: 800;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              color: #ffffff;
              background: #03a20a;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.modal-container-wrapper {
  padding: 1.25rem;
  padding-top: 0;

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0.625rem;
    padding-top: 0;
    // margin-top: 29px;

    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    text-align: center;

    color: $color-black;
  }

  .content-body {
    padding: 1.25rem;
  }
  .module-skills-section {
    padding: 1rem 0;
  }

  .next-module-action {
    display: flex;
    justify-content: center;
    align-items: center;

    .next-btn {
      width: 160px;
      height: 40px;
      color: white;
      background: #fac30f;
      border-radius: 5px;
      &.disabled {
        background-color: $modules-disabled-color;
      }
    }
    .next-module-button {
      background-color: $color-dark-blue;
      height: 3.125rem;
      border-radius: 0.625rem;
      padding: 0 1.25rem;

      &.disabled {
        background-color: $modules-disabled-color;
      }

      &:hover {
        background-color: $color-dark-blue;
      }

      .arrow-icon {
        background-color: $arrow-icon-color;
        border-radius: 3.125rem;
        padding: 0.3125rem;
        font-size: 1.125rem;
        right: 1.25rem;
        top: 0.625rem;
      }
    }
  }
}
.skills-auto-box {
  height: auto;
}

.create-module-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: $color-black;
}

.create-module-action {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0.625rem;
  right: 0.625rem;

  .save-changes-button {
    margin-right: 10px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  }

  .save-button {
    background-color: $create-module-color;
    color: $color-primary-blue;
    height: 3.125rem;
    border-radius: 0.625rem;
    padding: 0 1.25rem;
    box-shadow: unset;

    &:hover {
      background-color: $create-module-color;
    }
  }

  .create-module-button {
    background-color: $create-module-button-color;
    color: $color-primary-blue;
    height: 3.125rem;
    border-radius: 0.625rem;
    padding: 0 1.25rem;
    box-shadow: unset;

    &:hover {
      background-color: $create-module-hover-color;
    }

    .arrow-icon {
      background-color: $arrow-icon-color;
      border-radius: 3.125rem;
      padding: 0.3125rem;
      font-size: 1.125rem;
      right: 1.25rem;
      top: 0.625rem;
    }
  }
}
