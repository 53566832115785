@import "../Utils/colors.scss";
@import "../Utils/fonts.scss";

.module-questions-main {
  width: 100vw;
  height: 100vh;
  background: none;
}

.module-questions-container {
  display: flex;
  flex-direction: row;
  margin-top: 3.4375rem;
  background: #f7f7fc;

  .questions-menu-icon {
    visibility: hidden;
  }

  .floating-nav {
    .questions-nav {
      display: none;
    }
  }

  .questions-nav {
    width: 18.75rem;
    padding: 1.25rem;
    border-right: 0.0625rem solid $color-light-grey;
    height: calc(100vh - 12.625rem);
    overflow: overlay;

    /* Code to remove scroll bar but persist the scroll behavior*/
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    /* Code to remove scroll bar but persist the scroll behavior*/

    .menu-close-icon {
      display: none;
    }

    .nav-module-name {
      font-weight: 700;
      font-size: 1.25rem;
      color: $modules-title-color;
      padding: 0.625rem;
      margin-bottom: 1.25rem;
    }

    .nav-item {
      padding: 0.625rem;
      white-space: pre-wrap;
      border-radius: 0.3125rem;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.008em;
      color: $color-light-black;
      margin: 0.3125rem 0;
      display: flex;
      align-items: center;
      height: 2.5rem;

      &:hover,
      &.selected {
        background-color: $very-light-gray;
        font-weight: 600;
        transition: background-color 0.5s linear ease-in-out;
      }

      &.admin-nav-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-item-actions {
          visibility: hidden;

          .drag-icon {
            cursor: move;
          }
        }

        &.editable {
          .nav-item-actions {
            visibility: visible !important;
          }
        }

        .input-root {
          color: $color-medium-black;
          font-size: 0.875rem;
        }

        &:hover {
          .nav-item-actions {
            visibility: visible;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }
        }
      }

      .nav-item-title {
        padding: 0 0.625rem;
      }

      .nav-item-weightage {
        color: #03a10a;
        font-weight: 700;
      }

      .tick-icon {
        font-size: 0.875rem;
        background: $tick-icon-color;
        color: $color-white;
        border-radius: 0.625rem;
        padding: 0.125rem;

        &.completed {
          background: $color-green;
        }
      }
    }

    .create-content-container {
      text-align: center;

      .create-content-button {
        height: 3.125rem;
        border-radius: 0.625rem;
        padding: 0 1.25rem;
        color: $color-dark-blue;
        width: 100%;

        .arrow-icon {
          background-color: $arrow-icon-color;
          border-radius: 3.125rem;
          padding: 0.3125rem;
          font-size: 1.125rem;
          right: 1.25rem;
          top: 0.625rem;
        }
      }

      .create-section-field {
        .outline-root {
          color: $color-dark-grey;
          width: 100%;
          height: 2.5rem;

          /* Overriding styles was not working through module scss file. So used general.scss to override the styles
                        &.MuiOutlinedInput-notchedOutline {
                            border-color: $color-primary-blue !important;
                            border-radius: 10px !important;
                        }
                    */
          .notchedOutline {
            border: none !important;
          }

          .input-root {
            height: 100% !important;
            border: none !important;
            font-size: 0.875rem;
          }
        }

        .create-section-action-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          margin-top: 1.25rem;

          .create-button,
          .create-button {
            border-radius: 0.375rem;
            padding: 0 0.625rem;
          }

          .cancel-button {
            color: $color-dark-blue;
            margin-right: 0.625rem;
          }

          .create-button {
            background-color: $color-dark-blue;
            color: $color-white;
          }
        }
      }
    }
  }

  .questions-body {
    width: calc(100% - 25rem);
    padding: 0 3.125rem;
    overflow-y: overlay;
    height: calc(100vh - 12.5rem);
    margin-top: 2.5rem;
    margin-bottom: 4rem;

    /* Code to remove scroll bar but persist the scroll behavior*/
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    &.disable-scroll {
      overflow-y: hidden;
    }

    .main-body-container {}

    .update-actions-btn-container {
      display: flex;
      justify-content: center;
      width: 100%;
      // height: 2.5rem;
      // position: absolute;
      // right: 10%;

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .due-date {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 1rem;
          font-weight: 700;
          font-size: 1.25rem;
          letter-spacing: 0.0187rem;
          color: red;
          height: 50px;
          width: 50%;
        }
      }

      .questions-add-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 10px;
        width: 87px;
        height: 10px;
      }

      .add-question-btn {
        margin-left: 0.625rem;
        color: $color-primary-blue;
        padding: 0.625rem 1.25rem;
        border: 2px solid $color-primary-blue;
        border-radius: 0.375rem;
        width: 6rem;
        background-color: white;
      }

      .save-btn,
      .cancel-btn,
      .add-que-btn {
        margin-left: 0.625rem;
        color: $color-primary-blue;
        padding: 0.625rem 1.25rem;
        border-radius: 0.375rem;
        background-color: $create-module-color;

        &:hover {
          background-color: $add-question-button;
        }
      }

      .cancel-btn {
        background-color: unset;

        &:hover {
          background-color: unset;
        }
      }
    }

    .module-section-nav-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0.5rem 0;
      margin-bottom: 0;

      &.no-mark-complete {
        margin-bottom: 4rem;
      }

      .nav-prev,
      .nav-next {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .mark-complete-btn {
      display: flex;
      justify-content: center;

      .button-root {
        background-color: $color-dark-blue;
        margin: 0.5rem;
        border-radius: 0.625rem;
        font-size: 0.875rem;
        height: 3.125rem;
        padding: 0 2.5rem;
        max-width: 200px;
        margin-bottom: 4rem;
      }
    }

    .create-question-button {
      position: absolute;
      right: 6.25rem;
      height: 2.5rem;
      color: $color-primary-blue;
      padding: 0.625rem 1.25rem;
      border-radius: 0.375rem;
      background-color: $create-module-hover-color;

      &:hover {
        background-color: $add-question-button;
      }
    }

    /* Code to remove scroll bar but persist the scroll behavior*/

    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: 0.0187rem;
      color: $greyish-blue;
      padding: 0.625rem 0;
      width: 100%;
      word-break: break-all;
    }

    .section-details {
      display: flex;
      flex-direction: row;
      height: 140px;
      background: #004aad;
      justify-content: space-around;
      align-items: center;

      .box {
        background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
        border: 1px solid #ededed;
        border-radius: 10px;
        height: 86px;
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          /* identical to box height */
          margin: 0.5rem;
          color: #262626;
        }

        .value {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 30px;
          /* identical to box height */
          //margin: 0.35rem;
          display: flex;
          margin: 0 0.5rem;
          color: #0e1555;
        }
      }
    }

    .questions-container {
      display: flex;
      flex-direction: column;
      margin: 1.5rem 0;

      .wrapper {
        margin: 1.25rem 0;

        .upper-label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          //margin: 1.25rem;
          .attempts {
            height: 35px;
            background: #004aad;
            border-radius: 6px;
            width: max-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: "Nunito";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            /* identical to box height, or 154% */
            justify-content: center;
            color: #ffffff;
            padding: 0 2rem;

            .attempts-indicator {
              visibility: visible;
              display: flex;
              flex-direction: row;

              .circle {
                margin-left: 5px;
              }
            }

            .attempts-indicator-short-web {
              padding-left: 1rem;
              display: unset;
              visibility: visible;
            }

            .attempts-indicator-short {
              display: none;
              visibility: hidden;
            }
          }

          .credits {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 130%;
            /* or 18px */

            letter-spacing: 0.008em;

            /* green */

            color: #03a20a;
          }
        }

        .question {
          //margin: 1.25rem 0;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          border-radius: 4px;
          padding: 22px;

          .status-title-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }

          .title {
            display: flex;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 1rem;
            color: $color-light-black;
            width: 80%;
          }

          .select-options {
            color: $color-dark-grey;
            display: flex;
            flex-direction: column;
          }

          .radio-options {
            display: flex;
            flex-direction: column;
            color: $color-dark-grey;
          }

          .input {
            width: 100%;
            padding-top: 0.625rem;

            .outline-root {
              color: $color-medium-black;
              width: inherit;
              height: 6.25rem;

              /* Overriding styles was not working through module scss file. So used general.scss to override the styles
                                &.MuiOutlinedInput-notchedOutline {
                                    border-color: $color-primary-blue !important;
                                    border-radius: 10px !important;
                                }
                            */
              .notchedOutline {
                border: none !important;
              }

              .input-root {
                height: 100% !important;
                border: none !important;
              }
            }
          }

          .select-options,
          .radio-options {
            .label-field-root {

              .radio-root,
              .option-root {
                color: $color-primary-blue !important;
              }

              .checkbox-disabled,
              .radio-disabled {
                color: $color-dark-grey !important;
              }
            }
          }
        }
      }

      // .section-container {

      &.creation-mode {
        width: 90%;
        height: calc(80vh - 6.25rem);
        margin-top: 1.25rem;
        overflow-y: overlay;
        padding: 0.625rem;
        padding-right: 2.5rem;

        .question {
          margin: 1.25rem 0;
          position: relative;
          border-radius: 0.375rem;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 0;

          &:hover {
            .header-container {
              .delete-container {
                visibility: visible !important;
              }
            }
          }

          &:last-child {
            margin-bottom: 6.25rem;
          }

          .header-container {
            display: flex;
            justify-content: space-between;

            .question-header {
              display: flex;
              justify-content: space-between;
              width: 100%;
              flex-wrap: wrap;
              background: #f8f8f8;
              border-radius: 5px;
              min-height: min-content;
              padding: 10px 10px;
              align-items: center;

              .question-type-card {
                box-sizing: border-box;
                width: 180px;
                height: 28px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .question-action-icon {
                  display: flex;
                  width: 60px;
                  justify-content: space-around;
                  align-items: center;
                }
              }

              .question-type {
                box-sizing: border-box;
                width: 100px;
                height: 28px;
                background: rgba(255, 255, 255, 0.2);
                border: 1px solid $question-type-color;
                border-radius: 5px;

                .question-type-txt {
                  width: 100%;
                  height: 100%;
                  font-family: $font-family-lato;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  color: $question-type-color;
                }
              }

              .actual-question {
                font-family: $font-family-lato;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                width: 70%;
                letter-spacing: 0.5px;

                .question-index {
                  display: flex;
                  align-items: center;
                  margin-right: 8px;
                }
              }

              .question-title {
                min-width: 75%;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                .title-prefix {
                  width: 3.125rem;
                }

                .input-root {
                  width: 90%;
                  color: $color-light-black !important;

                  &::before {
                    border-color: $color-light-grey;
                  }

                  &::after {
                    border-color: $color-gray;
                  }
                }

                .title {
                  .menu-item-text {}
                }
              }

              .question-type {
                .form-root {
                  width: 9.5rem;
                  height: 2.5rem;
                }

                .menu-select {
                  display: flex;
                  align-items: center;
                  font-size: 0.75rem;
                }
              }
            }

            .delete-container {
              visibility: hidden;
              font-size: 0.75rem;
              font-weight: 600;
              margin-left: 1.25rem;
              color: $color-red;
              bottom: 0.3125rem;
              cursor: pointer;
            }
          }

          .options-container {
            .outline-root {
              width: calc(70% - 3.125rem);
              font-size: 0.875rem;
              margin-left: 2.5rem;
              margin-top: 1.25rem;
            }
          }
        }
      }

      .feedback-wrapper {
        display: flex;
        flex-direction: column;

        .view-feedback-accordion {
          display: flex;
          flex-direction: column;

          .view-feedback-label {
            cursor: pointer;
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.375rem;
            display: flex;
            align-items: center;
            color: $color-light-blue;
            margin-top: 0.6875rem;

            .icon-class-up,
            .icon-class-down {
              width: 1.5rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}

// for admin creation
.menu-item-root {
  font-size: 0.75rem !important;
}

.selected-item {
  background-color: $selected-item-color !important;
}

.menu-item-text {
  padding: 0.3125rem;
}

.custom-modal-paper {
  max-width: 1000px !important;
}

//question creation
.modal-container-wrapper {
  display: flex;
  height: 500px;

  .modal-question-section {
    width: 60%;
    overflow-y: auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
    background-color: #f3f8ff;

    .optionsCard {
      margin-top: 20px;

      .addOptionInput {
        width: 100%;
        height: 20px;
        margin-top: 20px;
      }
    }

    .feedback-divider {
      margin-top: 20px;
      border: 1px solid #e2e2e2;
    }

    .feedback-card {
      margin-top: 1rem;
      position: relative;

      .model-add-questions {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #262626;
        width: 50%;
        position: absolute;
      }
    }

    .saveTxt {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;
      margin-top: 15px;
      background: linear-gradient(107.28deg, #0e1555 38.14%, #142297 98.54%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .optionsHeader {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #262626;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
    }

    .add-option-delete-container {
      display: flex;
      color: red;
      cursor: pointer;
    }

    .setAnswerValue {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;

      .model-add-questions {
        display: flex;
      }
    }

    .setAnswerOptionsImg {
      margin-right: 10px;
    }

    .setOptionsRadio {
      width: 18px;
      height: 18px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 100px;
    }

    .addOptionsCard {
      display: flex;
      cursor: pointer;
      margin-top: 20px;

      .addOptionsTxt {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin-left: 10px;
        background: linear-gradient(107.28deg, #0e1555 38.14%, #142297 98.54%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .modal-header {
      width: 132px;
      height: 22px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #142297;
    }

    .content-wrapper {
      margin-top: 1rem;
      position: relative;
      align-items: center;
      display: flex;

      .model-add-questions {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #262626;
        width: 50%;
        margin-top: 1rem;
        position: absolute;
      }
    }

    .model-question-editor {
      width: 100%;
      margin: 0 auto;
      margin-top: 50px;
      height: 110px;
      background: #ffffff;
      border: 1px solid #e9e6e6;
    }

    .model-questions {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .model-questions-type {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #262626;
      }
    }

    .question-type-select {
      width: 200px;
      height: 20px;
      background-color: white;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }
  }

  .modal-grading-section {
    width: 30%;
    overflow-y: auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    .model-questions-type {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #262626;
    }

    .grading-type {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.22px;
      color: #000000;
    }

    .weightage-input-wrapper {
      display: flex;
      flex-direction: row;

      .weightage-input {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .weightage-label {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: flex-end;
          color: #262626;
          margin-bottom: 5px;
        }

        input[type="number"] {
          background: #f7f7f7;
          border: 1px solid #ebebeb;
          border-radius: 6px;
          width: 150px;
          height: 46px;
          text-align: center;
        }

        .credit-left {
          width: 53px;
          height: 46px;
          border: 1px solid #ebebeb;
          border-radius: 6px;
          color: #03a10a;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .separator {
      margin: 28px 0;
      border: 1px solid #cccccc;
    }

    .resubmission-section {
      input[type="number"] {
        background: #f7f7f7;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        width: 150px;
        height: 46px;
        text-align: center;
      }

      .label-switch-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .headers {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: flex-end;
          color: #262626;
          margin-bottom: 5px;
        }
      }

      .resubmission-limit-checker {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .button-section {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;

      .cancel-btn {
        width: 160px;
        margin-right: 10px;
      }

      .create-btn {
        width: 160px;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .module-questions-container {
    .questions-body {
      .questions-container {
        &.creation-mode {
          .question {
            .header-container {
              .question-header {
                .actual-question {
                  width: 70%;
                }
              }
            }

            .options-container {
              .outline-root {
                width: calc(100% - 4.375rem);
                margin-left: 1.5625rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .module-questions-container {
    .questions-body {
      .questions-container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .module-questions-container {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    position: relative;

    .questions-menu-icon {
      visibility: visible;
      position: absolute;
      top: 0.625rem;
      right: -0.625rem;
      cursor: pointer;
    }

    .questions-nav {
      display: none;
    }

    .questions-body {
      width: 100%;
      padding: 0;
      margin-top: 3.125rem;
      
      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 18rem);
      }

      .section-details {
        .box {
          .value {
            font-size: medium;
          }
        }
      }

      .questions-container {
        .wrapper {
          .question {
            .title {
              font-size: medium;
            }

            .select-options {}

            .radio-options {
              font-size: small;

              .label-field-root {
                font-size: small;

                .radio-root,
                .option-root {}
              }
            }

            .input {


              .outline-root {

                .notchedOutline {}

                .input-root {
                  font-size: small;
                }
              }
            }
          }

          .upper-label {
            .attempts {
              .attempts-indicator {
                display: none;
                visibility: hidden;
              }

              .attempts-indicator-short-web {
                display: none;
                visibility: hidden;
              }

              .attempts-indicator-short {
                padding-left: 1rem;
                display: unset;
                visibility: visible;
              }
            }
          }
        }
      }

      .mark-complete-btn {
        .button-root {
          font-size: 0.675rem;
          height: 2.5rem;
          margin-bottom: 2rem;
        }
      }
    }

    .title {
      font-size: small;
    }

    .module-section-nav-wrapper {

      .nav-prev,
      .nav-next {
        font-size: small;
      }
    }



    &.with-hidden-nav {
      .floating-nav {
        width: 100vw;
        position: absolute;
        height: 100vh;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);

        .questions-nav {
          display: revert !important;
          position: absolute;
          z-index: 1;
          background-color: $color-white;
          height: 100vh;
          left: -1.25rem;

          .menu-close-icon {
            width: 100%;
            text-align: right;
            cursor: pointer;
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .module-questions-container {
    .questions-body {
      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 18rem);
      }
    }
    .questions-nav {
      left: -1.25rem;
      width: calc(100vw - 2.5rem);
    }
  }
}