@import "../../../Utils/colors.scss";
@import "../../../Utils/fonts.scss";

.container-root {
  width: calc(100% - 4rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

  .create-onboarding-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: $color-black;
    .create-onboarding-header-left {
      display: flex;
      width: 100%;
    }
    .create-onboarding-header-right {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .questions-body {
    width: 100%;
    padding: 0 3.125rem;
    overflow-y: overlay;
    //height: calc(100vh - 12.5rem);
    margin-top: -1.5rem;

    /* Code to remove scroll bar but persist the scroll behavior*/
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    &.disable-scroll {
      overflow-y: hidden;
    }

    .main-body-container {
      .questions-container {
        display: flex;
        flex-direction: column;

        // .section-container {
        .question {
          margin: 1.25rem 0;

          .status-title-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .icon-text-wrapper {
              display: flex;
              align-items: center;
              &.ReSubmit {
                color: $color-orange !important;
              }
              &.Completed {
                color: $color-green;
              }
              .menu-icon {
                font-size: 1.25rem !important;
              }
              .Submitted {
                margin-left: 0.625rem;
              }
              .ReSubmit {
                margin-left: 0.625rem;
                color: $color-orange !important;
              }
              .Completed {
                margin-left: 0.625rem;
                color: $color-green;
              }
            }
          }

          .title {
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 1rem;
            color: $color-light-black;
            width: 80%;
          }

          .select-options {
            color: $color-dark-grey;
            display: flex;
            flex-direction: column;
          }

          .radio-options {
            color: $color-dark-grey;
          }

          .input {
            width: 100%;
            padding-top: 0.625rem;

            .outline-root {
              color: $color-medium-black;
              width: inherit;
              height: 6.25rem;
              .notchedOutline {
                border: none !important;
              }

              .input-root {
                height: 100% !important;
                border: none !important;
              }
            }
          }

          .select-options,
          .radio-options {
            .label-field-root {
              .radio-root,
              .option-root {
                color: $color-primary-blue !important;
              }

              .checkbox-disabled,
              .radio-disabled {
                color: $color-dark-grey !important;
              }
            }
          }
        }

        &.creation-mode {
          width: 90%;
          height: calc(86vh - 6.25rem);
          margin-top: 1.25rem;
          overflow-y: overlay;
          padding: 0.625rem;
          padding-right: 2.5rem;

          .question {
            margin: 1.25rem 0;
            padding: 1.25rem;
            position: relative;
            border-radius: 0.375rem;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;

            &:hover {
              .header-container {
                .delete-container {
                  visibility: visible !important;
                }
              }
            }

            &:last-child {
              margin-bottom: 6.25rem;
            }

            .header-container {
              display: flex;
              justify-content: space-between;

              .question-header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                flex-wrap: wrap;

                .question-title {
                  min-width: 75%;
                  display: flex;
                  align-items: center;
                  flex-wrap: nowrap;

                  .title-prefix {
                    width: 3.125rem;
                  }

                  .input-root {
                    width: 90%;
                    color: $color-light-black !important;

                    &::before {
                      border-color: $color-light-grey;
                    }

                    &::after {
                      border-color: $color-gray;
                    }
                  }

                  .title {
                    .menu-item-text {
                    }
                  }
                }

                .question-type {
                  .form-root {
                    width: 9.5rem;
                    height: 2.5rem;
                  }

                  .menu-select {
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                  }
                }
              }

              .delete-container {
                visibility: hidden;
                font-size: 0.75rem;
                font-weight: 600;
                margin-left: 1.25rem;
                color: $color-red;
                bottom: 0.3125rem;
                cursor: pointer;
              }
            }

            .options-container {
              .outline-root {
                width: calc(70% - 3.125rem);
                font-size: 0.875rem;
                margin-left: 2.5rem;
                margin-top: 1.25rem;
              }
            }
          }
          .additional-details-section {
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            .heading {
              font-family: "Lato";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 19px;

              color: #000000;

              opacity: 0.7;
            }
            .dta-section {
              display: flex;
              flex-direction: column;
              .question {
                font-family: "Lato";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;

                display: flex;
                align-items: center;
                letter-spacing: 0.5px;

                color: #000000;
                .mandatory {
                  color: $color-red;
                  vertical-align: middle;
                }
              }
              .checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;
                .wrapper {
                  display: flex;
                  flex-direction: row;
                  width: 40%;
                  .mandatory {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 40%;
                    .checkbox-root {
                      color: $color-primary-blue !important;
                    }
                  }
                  .optional {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 30px;
                    .checkbox-root {
                      color: $color-primary-blue !important;
                    }
                  }
                }
              }
            }
          }
        }

        .feedback-wrapper {
          display: flex;
          flex-direction: column;

          .view-feedback-accordion {
            display: flex;
            flex-direction: column;

            .view-feedback-label {
              cursor: pointer;
              font-family: $font-family-primary;
              font-style: normal;
              font-weight: 600;
              font-size: 0.75rem;
              line-height: 1.375rem;
              display: flex;
              align-items: center;
              color: $color-light-blue;
              margin-top: 0.6875rem;

              .icon-class-up,
              .icon-class-down {
                width: 1.5rem;
                height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .questions-body {
    .questions-container {
      width: 100%;
    }
  }
}
