
@import '../Utils/colors.scss';

.cohort-application-container {
    height: 100%;
    .backto-container {
        margin-top: 2.5rem;
        padding-top: 1.25rem;
        display: flex;
        align-items: center;
        color: $color-light-black;
        cursor: pointer;
        svg {
            font-size: 1rem;
            padding-right: 0.625rem;
            font-weight: normal;
            color: $color-light-black;
        }
        &:hover {
            color: $color-primary-blue;
            font-weight: 600;
        }
    }
    .questions-main {
        width: 60%;
        margin: 0 auto;
        margin-top: 5rem;
        .header {
            font-size: 1.5rem;
            font-weight: bold;
            color: #252733;
            width: 100%;
            text-align: center;
        }
        .questions-container {
            margin-top: 1.25rem;
            .question {
                padding: 1.25rem 0;
                .title {
                    padding-bottom: 0.625rem;
                    font-weight: 600;
                    color: $color-medium-black;
                    font-size: 1.125rem;
                    display: flex;
                    flex-direction: row;
                    .prefix {
                        width: 3.125rem;
                    }
                }
                .select-options {
                    width: calc(100% - 3.125rem);
                    padding-top: 1.25rem;
                    color: $color-dark-grey;
                    display: flex;
                    flex-direction: column;
                    margin-left: 3.125rem;
                }
                .radio-options {
                    width: calc(100% - 3.125rem);
                    display: flex;
                    flex-direction: column;
                    padding-top: 1.25rem;
                    color: $color-dark-grey;
                    margin-left: 3.125rem;
                }
                .input {
                    padding-top: 1.25rem;
                    width: calc(100% - 3.125rem);
                    display: flex;
                    margin-left: 3.125rem;
                    .outline-root {
                        color: $color-dark-grey;
                        width: inherit;
                        height: 6.25rem;
                    }
                    .user-input-field {
                        height: 100% !important;
                    }
                }
            }
        }
    }
    .action-container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
        padding-bottom: 2rem;
        .button-root {
            width: 12.5rem;
            cursor: pointer;
            background-color: rgba(25, 118, 210, 0.1);
            height: 3.125rem;
            color: #0E1555;
            border-radius: 0.625rem;

            .arrow-icon {
                background-color: rgba(255, 255, 255, 0.31);
                border-radius: 3.125rem;
                padding: 0.3125rem;
                font-size: 1.125rem;
                right: 1.25rem;
                top: 0.625rem;
            }
        }

        .button-root-disabled {
            width: 12.5rem;
            cursor: not-allowed;
            background-color: lightgray !important;
            color: rgb(111, 111, 111);
            height: 3.125rem;
            border-radius: 0.625rem;
        }
    }
}

.label-field-root {
    .radio-root, .option-root {
        color: $color-primary-blue !important;
    }
}

.optin-msg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .checkbox-root {
        color: $color-primary-blue !important;
    }
}



@media only screen and (max-width: 1000px) {
    .cohort-application-container {
        .questions-main {
            width: calc(100% - 3.75rem);
        }
    }
}