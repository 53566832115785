@import "../Utils/colors.scss";

.content-wrapper {
  display: flex;
  width: 100%;
  &.question-editor-wrapper {
    // add styles here
  }

  .title-desc-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-header,
    .desc-header {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: $desc-header-color;
    }

    .title-root {
      background: $color-white;
    }

    .notched-outline-root {
      border-color: $color-primary-blue;
      border-radius: 0.625rem;
    }

    .user-input-field {
      height: 100% !important;
    }

    .title-header,
    .desc-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: $desc-header-color;
      margin-bottom: 0.3125rem;
    }

    .form-control,
    .form-control-multiline {
      .title-label {
        width: 34px;
        height: 19px;

        //font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #333333;
      }
      .title-root,
      .desc-root {
        height: 100%;
        // margin-bottom: 1.625rem;
        background: unset;
        border: unset;
        background: $form-desc-root-color;
        border-radius: 0.1875rem 0.1875rem 0rem 0rem;
      }
      .char-length {
        align-self: flex-end;
      }
    }

    .form-control {
      margin-bottom: 20px;
    }

    .form-control-multiline {
      height: 7.5rem;
    }
  }
}
