@import "../../../Utils/colors.scss";

.menu-item-root {
  font-size: 0.875rem !important;
  &.applied,
  &.review,
  &.accepted,
  &.rejected {
    .menu-icon {
      font-size: 1.25rem !important;
    }
    .menu-item-text {
      margin-left: 0.625rem;
    }
  }
  &.review {
    color: $color-orange !important;
  }
  &.accepted {
    color: $color-green;
  }
  &.rejected {
    color: $color-red;
  }
}
.search {
  display: flex;
  justify-content: flex-end;
  padding: 0.625rem;
  .search-field-root {
    width: 18.75rem;
  }
}

.filter-div {
  display: flex;
  height: 3.125rem;
  flex-direction: row-reverse;
  justify-content: space-between;
  background: #ffffff;
  border: 0.0625rem solid #c2c2c2;
  border-radius: 0.625rem;
  margin: 1rem;
  align-items: center;
  .total-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
    margin: 1rem;
    color: #262626;
  }
}

.container-root {
  width: calc(100% - 4rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: $color-white;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);
  position: relative;

  .applications-header-root {
    .header-row {
      .header-cell {
        background-color: $application-header-color;
        color: $header-cell-color;
      }
    }
  }

  .body-cell {
    &.name {
      .name-container {
        display: flex;
        align-items: center;
        position: relative;
        .profile-img {
          height: 1.875rem;
          .section-badge {
            position: absolute;
            left: 0.5rem;
            top: -0.25rem;
          }
          .name-letters {
            background-color: $color-dark-blue;
            color: $color-white;
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 3.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.3125rem;
            font-size: 0.75rem;
          }
          .user-image {
            height: 100%;
            object-fit: contain;
            border-radius: 3.125rem;
            margin-right: 0.3125rem;
          }
        }
        .name-text {
        }
      }
    }
    .form-root {
      height: 2.5rem;
      width: 12.5rem;
      .menu-select {
        font-size: 0.875rem;
        display: flex;
        flex-direction: row;
        &.applied,
        &.review,
        &.accepted,
        &.rejected {
          .menu-icon {
            font-size: 1.25rem !important;
          }
          .menu-item-text {
            margin-left: 0.625rem;
          }
        }
        &.review {
          color: $color-orange;
        }
        &.accepted {
          color: $color-green;
        }
        &.rejected {
          color: $color-red;
        }
      }
    }
  }

  .modules-tiles-wrapper {
    background: modules-title-wrapper-color;
    position: relative;
    border-radius: 0.3125rem;
    margin: 2.5rem;
    box-shadow: none;
    cursor: pointer;
    box-shadow: 0rem 0.25rem 0.1875rem 0rem $box-shadow-color;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.005);
      transition: transform 0.2s ease-in-out;
    }

    .module-otpions-container {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .edit-icon {
        padding: 0.625rem;
        cursor: pointer;
      }

      .pubnub-switch-root {
        color: $color-primary-blue;
      }

      .pubnub-switch-root-inactive {
        color: $color-gray;
      }
    }

    .modules-tiles-content {
      .modules-content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .modules-header {
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 0.75rem;
          line-height: 1rem;
          letter-spacing: 0.1875rem;
          text-transform: uppercase;
          color: $color-secondary-blue;
        }

        .modules-title {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.5rem;
          letter-spacing: 0.0125rem;
          color: $modules-title-color;
          padding-top: 1.125rem;
          padding-bottom: 0.625rem;
        }

        .modules-description {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 150%;
          letter-spacing: 0.0125rem;
          color: $color-light-black;
        }
      }
    }
  }
}

.modal-container-wrapper {
  padding: 1.25rem;
  padding-top: 0;

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0.625rem;
    padding-top: 0;
    // margin-top: 29px;

    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    text-align: center;

    color: $color-black;
  }

  .content-body {
    padding: 1.25rem;
  }

  .next-module-action {
    display: flex;
    justify-content: center;
    align-items: center;

    .next-module-button {
      background-color: $color-dark-blue;
      height: 3.125rem;
      border-radius: 0.625rem;
      padding: 0 1.25rem;

      &.disabled {
        background-color: $modules-disabled-color;
      }

      &:hover {
        background-color: $color-dark-blue;
      }

      .arrow-icon {
        background-color: $arrow-icon-color;
        border-radius: 3.125rem;
        padding: 0.3125rem;
        font-size: 1.125rem;
        right: 1.25rem;
        top: 0.625rem;
      }
    }
  }
}

.create-module-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: $color-black;
}

.create-module-action {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0.625rem;
  right: 0.625rem;

  .create-module-button {
    background-color: $create-module-color;
    color: $color-primary-blue;
    height: 3.125rem;
    border-radius: 0.625rem;
    padding: 0 1.25rem;
    box-shadow: unset;

    &:hover {
      background-color: $create-module-color;
    }

    .arrow-icon {
      background-color: $arrow-icon-color;
      border-radius: 3.125rem;
      padding: 0.3125rem;
      font-size: 1.125rem;
      right: 1.25rem;
      top: 0.625rem;
    }
  }
}
