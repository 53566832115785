@import "../Utils//colors.scss";

.auth-modal-container {
  .auth-modal-paper {
    border-radius: 0.875rem;
  }
}

.auth-modal-body {
  width: 30rem;
  height: 37.5rem;
  position: relative;

  .close-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    svg {
      cursor: pointer;
    }
  }

  &.signup {
    height: 48rem;
  }

  .header {
    margin-bottom: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-transform: uppercase;
      font-size: 1.5rem;
      margin: 0;
      line-height: 2.0625rem;
      letter-spacing: 0.2px;
    }

    .secondary-title {
      font-size: 1.5rem;
      margin: 0;
      line-height: 2.0625rem;
      letter-spacing: 0.2px;
    }

    .subtitle {
      color: $color-cool-grey;
      font-size: 1rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 3.125rem);
    margin: 0 auto;
    margin-top: 3.75rem;

    .name-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .form-control {
        width: 46%;
      }
    }

    .form-control {
      margin-bottom: 1.25rem;

      .input-label {
        color: $color-cool-grey;
      }

      .notched-outline-root {
        border-color: $color-primary-blue;
        border-radius: 0.625rem;
      }

      .user-input-field {
        padding-left: 0.625rem;
      }

      .outline-root {
        .user-icon {
          cursor: default;
        }
        .icon-button-root {
          margin-right: -0.3125rem;
        }
      }

      .option-root {
        color: $color-primary-blue !important;
      }

      // &.MuiFormControl-root {
      //     .MuiOutlinedInput-root {
      //         .MuiOutlinedInput-notchedOutline {
      //             border-color: $color-primary-blue !important;
      //             border-radius: 0.625rem !important;
      //         }
      //     }
      // }
    }

    .links-container {
    }

    .org-login-link {
      text-align: left;
      padding: 0.3125rem;
      padding-bottom: 0.625rem;
      color: $color-primary-blue;
      cursor: pointer;
    }

    .reset-pass-link {
      float: right;
      padding: 0.3125rem;
      padding-bottom: 0.625rem;
      color: $color-primary-blue;
      cursor: pointer;
    }

    .button-root {
      background-color: $color-dark-blue;
      height: 3.125rem;
      border-radius: 0.625rem;

      .arrow-icon {
        background-color: rgba(255, 255, 255, 0.31);
        border-radius: 3.125rem;
        padding: 0.3125rem;
        font-size: 1.125rem;
        position: absolute;
        right: 1.25rem;
        top: 0.625rem;
      }
    }

    .breaking-label {
      color: $color-cool-grey;
      margin: 0 auto;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      position: relative;
      text-align: center;
      width: 100%;

      &::after {
        content: "";
        display: block;
        width: 40%;
        height: 0.0625rem;
        background: $color-light-grey;
        right: 0;
        top: 50%;
        position: absolute;
      }

      &::before {
        content: "";
        display: block;
        width: 40%;
        height: 0.0625rem;
        background: $color-light-grey;
        left: 0;
        top: 50%;
        position: absolute;
      }
    }

    .social-login-container {
      .google-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0rem 0rem 0.375rem 0.125rem $color-light-grey;
        border-radius: 0.625rem;
        .google-img-btn {
          padding: 0.9375rem;
          border-radius: 3.125rem;
          height: 1.25rem;
          object-fit: contain;
        }
      }
    }

    .footer-container {
      margin: 0 auto;
      padding-top: 2.5rem;
      padding-bottom: 0.625rem;
      white-space: nowrap;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      .auth-modal-toggle-btn {
        color: $color-primary-blue;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .footer-container-more {
      margin: 0 auto;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      width: inherit;
      .auth-modal-toggle-btn {
        text-decoration-color: unset;
        color: $color-primary-blue;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .auth-modal-body {
    width: calc(100% - 3.125rem);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
  }
}
