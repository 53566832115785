@import "../Utils/colors.scss";
@import "../Utils/fonts.scss";

.main-div {
  .main-container {
    padding-right: 0;
    .theme-container-header {
      font-family: $font-family-lato;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5rem;
      display: flex;
      letter-spacing: 0.02em;
      @media only screen and (max-width: 1000px) {
        margin: 2rem;
      }
      margin: 2rem 0;
      color: #000000;
    }
    .modules-tiles-wrapper {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      justify-content: space-evenly;
      height: calc(100vh - 13rem);
      @media only screen and (max-width: 1000px) {
        height: 1200px;
      }
      &:hover {
        transform: scale(1.005);
        transition: transform 0.2s ease-in-out;
      }
      .heading-for-tiles {
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        @media only screen and (max-width: 1000px) {
          margin: 2.5rem;
        }
        margin: 1rem;
        color: #000000;
      }
      .preview-section {
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 1000px) {
          flex-direction: column;
        }
        width: 100%;
        flex-basis: 100%;
        .left-side-preview {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex-basis: 50%;
          @media only screen and (max-width: 1000px) {
            width: 100%;
          }
          .primary-color-section {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 1rem;
            justify-content: space-between;
            @media only screen and (max-width: 1000px) {
              margin: 0rem;
            }
            .label-section {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              @media only screen and (max-width: 1000px) {
                margin: 1rem;
                font-size: 14px;
              }
              color: #000000;
            }
            .input-color-section {
              height: 50px;

              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-left: 10px;
              background: #ffffff;
              border: 1px solid #a5a6b6;
              border-radius: 3px;
              width: 30%;
              @media only screen and (max-width: 1000px) {
                width: 50%;
              }
              font-size: medium !important;
              .input-primary-color {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: transparent;

                height: 50px;
                border: none;
                cursor: pointer;
              }
              .input-primary-color::-webkit-color-swatch {
                border-radius: 50%;
              }
            }
          }
          .secondary-color-section {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            @media only screen and (max-width: 1000px) {
              margin: 0rem;
            }
            margin: 1rem;
            .label-section {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              @media only screen and (max-width: 1000px) {
                margin: 1rem;
                font-size: 14px;
              }
              color: #000000;
            }
            .input-color-section {
              height: 50px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: medium !important;
              background: #ffffff;
              border: 1px solid #a5a6b6;
              border-radius: 3px;
              width: 30%;
              @media only screen and (max-width: 1000px) {
                width: 50%;
              }

              .input-secondary-color {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: transparent;

                height: 50px;
                border: none;
                cursor: pointer;
              }
              .input-secondary-color::-webkit-color-swatch {
                border-radius: 50%;
              }
            }
          }
          .font-type-section {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 1rem;
            @media only screen and (max-width: 1000px) {
              margin: 0rem;
            }
            justify-content: space-between;
            .label-section {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              @media only screen and (max-width: 1000px) {
                width: 50%;
                margin: 1rem;
                font-size: 14px;
              }
              width: 30%;
              color: #000000;
            }
            .input-section {
              height: 50px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: medium !important;
              border-radius: 3px;
              width: 30%;
              select {
                padding: 15px 5px;
                border-radius: 4px;
                cursor: pointer;
                border: 1px solid #a5a6b6;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                &:hover {
                  outline: none;
                }
                option {
                  background: #ffffff;
                }
              }
            }
          }
          .upload-logo-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media only screen and (max-width: 1000px) {
              margin: 0rem;
            }
            margin: 1rem;
            justify-content: space-between;
            .label-section {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              @media only screen and (max-width: 1000px) {
                font-size: 14px;
                margin: 1rem 0;
                margin-left: 15px;
              }
              width: 70%;
              color: #000000;
              margin-left: 5px;
            }
            .input-logo-section {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              width: 30%;
              height: 100px;
              background: #f7f7f7;
              border: 1.5px dashed #142297;
              border-radius: 4px;
              form {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                label {
                  display: flex;
                  flex-direction: column;
                  width: inherit;
                  overflow-wrap: break-word;
                  padding: 5px;
                  text-align: center;
                  cursor: pointer;
                }
                .upload-button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  opacity: 0;
                  width: 142px;
                  height: 100px;
                  position: absolute;
                }
              }
            }
          }
        }
        .right-side-preview {
          width: 50%;
          @media only screen and (max-width: 1000px) {
            width: 100%;
          }
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          color: #484848;
          align-items: center;
          .preview-window {
            @media only screen and (max-width: 1000px) {
              width: 100%;
            }
            @media only screen and (min-width: 1000px) {
              width: 75%;
            }
            width: 50%;
            height: 200px;
            display: flex;
            background: #f3f3f3;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            .preview-mini-window {
              background: #ffffff;
              border-radius: 6px;
              height: 150px;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              width: 75%;
              justify-content: space-around;
              .preview-button {
                height: 50px;
              }
            }
          }
          .present-theme-preview {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem 0;
            .present-window {
              width: 50%;
              @media only screen and (max-width: 1000px) {
                width: 100%;
                margin: 2rem 0;
              }
              @media only screen and (min-width: 1000px) {
                width: 75%;
              }
              height: 200px;
              display: flex;
              background: #f3f3f3;
              border-radius: 4px;
              justify-content: center;
              align-items: center;
              .present-mini-window {
                background: #ffffff;
                border-radius: 6px;
                width: 75%;
                @media only screen and (max-width: 1000px) {
                  width: 80%;
                }

                height: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;

                justify-content: space-around;
                .preview-button {
                  height: 50px;
                }
              }
            }
          }
        }
      }
    }

    .save-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 40%;
      margin-bottom: 2rem;
      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        margin-left: 40%;
      }
      .cancel-button {
        width: 120px;
        height: 48px;
        color: #ffffff;
        background: #a8a8a8;
        border: 1px solid #a8a8a8;
        border-radius: 5px;
      }
      .save-button {
        width: 120px;
        height: 48px;
        color: #ffffff;
        margin-left: 30px;
        border-radius: 5px;
        @media only screen and (max-width: 1000px) {
          margin-left: 0px;
        }
      }
      .reset-button {
        width: 84px;
        height: 38px;
        color: green;
      }
    }
  }
}
