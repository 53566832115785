@import "../Utils/colors.scss";
@import "../Utils/fonts.scss";

.main-div {
  display: flex;
  flex-direction: column;
  .page-heading {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
    padding-left: 1.375rem;

    color: #000000;
  }
  .mini-window {
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    margin: 2rem;
    padding-bottom: 0.75rem;
    .form-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0.75rem;

      .section-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font-family-lato;
        font-style: normal;
        justify-content: space-evenly;
        font-weight: 600;
        font-size: 1.175rem;
        margin: 10px 0px;
        > label {
          display: flex;
          width: 20%;
        }
      }
    }
    .button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .save-btn {
        width: 120px;
        height: 48px;
        color: #ffffff;
        margin-left: 30px;
        border-radius: 5px;
      }
      .cancel-btn {
        width: 120px;
        height: 48px;
        color: #ffffff;
        background: #a8a8a8;
        border: 1px solid #a8a8a8;
        border-radius: 5px;
        margin-left: 50px;
      }
    }
  }
}
