@import "../Utils//colors.scss";

.welcome-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: inherit;
  position: relative;
  overflow: hidden;

  .welcome-modal-title {
    margin-top: 2.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .welcome-modal-subtitle {
    font-size: 1.5rem;
    margin: 0;
    line-height: 2.0625rem;
    letter-spacing: 0.2px;
  }

  ul {
    padding-inline-start: 0;
  }

  .welcome-modal-bottom-subtitle {
    font-size: 1.2rem;
    margin: 0;
    padding: 0.3125rem;
    line-height: 1.625rem;
    letter-spacing: 0.2px;
    text-align: center;
  }
  @media screen and (max-width: 425px) {
    .welcome-modal-title {
      margin-top: 2.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .welcome-modal-subtitle {
      font-size: 0.875rem;
      margin: 0;
      line-height: 1.325rem;
      letter-spacing: 0.2px;
    }

    ul {
      padding-inline-start: 0;
      font-size: 0.75rem;
    }

    .welcome-modal-bottom-subtitle {
      font-size: 0.6875rem;
      margin: 0;
      padding: 0.3125rem;
      line-height: 1.125rem;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .welcome-modal-footer {
      font-size: 0.625rem;
    }
  }
}
