@import "../Utils/colors.scss";

.navbar-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid rgba(230, 230, 225, 0.9);
  padding: 0.3125rem;
  height: 3.75rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: $color-white;

  .nav-left {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      margin-top: 0.625rem;
      padding-left: 1.25rem;
    }
  }

  .nav-right {
    .nav-right {

      &-private,
      &-public {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.625rem;
        border-bottom: 0.0625rem solid rgba(216, 216, 211, 0);

        .list-item {
          cursor: pointer;
          // margin-left: 0.625rem;
          padding: 0.3125rem;
          // padding-left: 0.625rem;
          padding-right: 0.625rem;
          color: $color-primary-blue;
          font-weight: 600;

          &:not(.profile) {
            &:hover {
              border-radius: 0.625rem;

              &:after {
                width: 95%;
                background: $color-primary-blue;
              }
            }
          }

          &:after {
            content: "";
            margin-top: 0.3125rem;
            display: block;
            margin: auto;
            margin-top: 0.3125rem;
            height: 0.1875rem;
            width: 0rem;
            background: transparent;
            transition: width 0.3s ease, background-color 0.3s ease;
          }
          &.first-child-style {
            &:after {
              content: "";
              margin-top: 0.3125rem;
              display: block;
              margin: auto;
              margin-top: 0.3125rem;
              height: 0.1875rem;
              width: 0rem;
              background: transparent;
              width: 100%; /* Set a default width */
              background: $color-primary-blue; 
              transition: width 0.3s ease, background-color 0.3s ease;
            }
          }

          .nav-list-icon {
            padding-right: 0.5rem;
          }

          .profile-container {
            height: 2.1875rem;
            width: 2.1875rem;

            .avatar {
              height: 100%;
              width: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .profile-container-no-img {
            height: 2.1875rem;
            width: 2.1875rem;
            display: flex;
            //background: #142297;
            color: $color-white;
            border-radius: 3.125rem;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &-public,
      &-private {
        padding-top: 0.625rem;
      }

      &-public {
        padding-top: 0.9375rem !important;
      }
    }
  }

  .nav-link {
    text-decoration: none;
    padding: 0.3125rem;
    color: $color-primary-blue;

    .logo-container {
      width: 100px;
      height: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar-container {
    .nav-left {
      .logo {
        padding-left: 0;
      }
    }

    .nav-right {
      .nav-right {

        &-private,
        &-public {
          .list-item {
            .profile-container {}

            .nav-list-icon {}

            .nav-item-name {
              display: none;
            }
          }
        }
      }
    }
  }
}