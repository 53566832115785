@import '../Utils/colors.scss';

.text-editor {
    .preview-action-container {
        width: 100%;
        text-align: right;
        margin-bottom: 1.25rem;

        .preview-option-btn {
            padding: 0.625rem;
            background-color: rgba(25, 118, 210, 0.1);
            color: #0E1555;
            height: 2.125rem;
            width: 6.25rem;
            border-radius: 0.3125rem;
            border: none;
            cursor: pointer;
            text-transform: capitalize;
        }
    }

    .ck-editor {
        .ck-editor__main {
            .ck-content {
                min-height: 12.5rem;
                max-height: calc(100vh - 18.75rem);
            }
        }
    }

    &.read-only {
        .ck-editor {
            .ck-editor__main {
                .ck-content {
                    max-height: unset;
                    border: unset;

                    img {
                        cursor: zoom-in;
                    }
                }
            }
        }

        .image {
            img {
                cursor: zoom-in;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.question-wrapper, .question-wrapper-admin {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    > div {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            > p {
                margin: 0;
                width: 100%;
            }
            * {
                width: 100%;
            }
        }
    }
    &.read-only {
        .ck-editor {
            display: flex;
            .ck-editor__main {
                display: flex;
                .ck-content {
                    display: flex;
                    flex-direction: column;
                    min-height: auto;
                    max-height: unset;
                    border: unset;
                    background: unset;
                    padding: unset;
                    p {
                        margin: unset;
                    }
                    img {
                        cursor: zoom-in;
                    }
                }
            }
        }

        .image {
            img {
                cursor: zoom-in;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.course-details-content-wrapper {
    &.read-only {
        .ck-editor {
            .ck-editor__main {
                .ck-content {
                    min-height: auto;
                    max-height: unset;
                }
            }
        }
    }
}

.event-details-content-wrapper {
    display: flex;
    &.read-only .ck-editor .ck-editor__main .ck-content {
        background: unset;
        display: flex;
        flex-direction: column;
        min-height: unset;
        height: 146px;
        width: 450px;
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
    }
    >div {
        >div {
            display: flex;
            flex-direction: column;
            height: 146px;
            width: 450px;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            > p {
                width: 100%;
                > span {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }
            }
        }
    }
}