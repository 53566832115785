.container-root {
    width: calc(100% - 2rem) !important;
    min-height: 53.125rem;
    padding: 0 !important;
    margin-left: 1.8125rem !important;
    margin-top: 1.8125rem !important;
    margin-right: 1.8125rem !important;
    background: #FFFFFF;
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

    .cohort-menu-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.5rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.5rem;
        letter-spacing: 0.02em;
        color: #000000;
    }

    .cohort-section-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0.625rem;
        overflow: hidden;
        overflow-y: auto;
        height: calc(100vh - 16rem);
        .cohort-section {
            position: relative;
            display: none;
            width: 29.875rem;
            height: 8.5625rem;
            margin-left: 1.5rem;
            margin-top: 1.5rem;
            background: linear-gradient(135deg, #FED992 0%, #EB5556 100%);
            box-shadow: 0rem 0.0625rem 0.625rem rgba(14, 21, 85, 0.1);
            border-radius: 0.625rem;
            cursor: not-allowed;
            &.active {
                display: flex;
                cursor: pointer;
            }
            .section-name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 2.5rem;
                color: #FFFFFF;
                padding: 0.75rem;
                position: relative;
                .section-badge {
                    margin-left: 1.5rem;
                }
            }
            .arrow-icon {
                position: absolute;
                background-color: white;
                border-radius: 3.125rem;
                padding: 0.3125rem;
                font-size: 1.125rem;
                right: 1.25rem;
                bottom: 1.25rem;
            } 
        }
    }

}

@media only screen and (max-width: 1200px) {
    .cohort-section-container {
        justify-content: center;
        .cohort-section {
            margin-left: 0 !important;
        }
    }
}