// Define a base size for rem calculations, typically 16px
$base-size: 16;

// Function to convert px to rem
@function px-to-rem($size) {
  @return calc($size / $base-size * 1rem);
}

.modalBackdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modalContent {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 9000;
  background-color: #f9f9f9;
  padding: px-to-rem(15);
  border-top: 1px solid #ccc;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.modalHeader {
  font-size: px-to-rem(16);
  font-weight: bold;
  margin-bottom: px-to-rem(5);
}

.modalBody {
  font-size: px-to-rem(14);
  margin-bottom: px-to-rem(10);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
    @media (max-width: px-to-rem(768)) {
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .agreeButton,
    .declineButton {
      width: 100%;
      margin-top: px-to-rem(10);
    }
  }
}

.agreeButton, .declineButton {
  padding: px-to-rem(5) px-to-rem(10);
  margin-left: px-to-rem(10);
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: px-to-rem(4);
  cursor: pointer;
}

