@import "../Utils/colors.scss";

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .button-root {
    background-color: $color-primary-blue;
    height: 3.125rem;
    border-radius: 0.625rem;
    width: 12.5rem;

    .arrow-icon {
      background-color: $arrow-icon-color;
      border-radius: 3.125rem;
      padding: 0.3125rem;
      font-size: 1.125rem;
      right: 1.25rem;
      top: 0.625rem;
    }

    &:hover {
      background-color: $color-primary-blue;
    }
  }
}

.gif-loader {
  background: url("../../Assets/loading.gif") no-repeat center center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  background-color: #fffffffc;
}

.cohort-container {
  overflow-x: hidden;

  .cohort-header-container {
    position: relative;
    background: $cohort-header-container-color;
    background: url(../../Assets/Images/banner.png);
    border-bottom-left-radius: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      width: 50%;
      color: $color-white;

      .main {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 2.25rem;
        }

        .sub {
          padding: 1.25rem 0;
        }
      }

      .sub-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        .sub-section-item-container {
          display: flex;
          align-items: center;
          padding: 0.625rem;

          .sub-section-item-image {}

          .sub-section-item-content {
            padding: 0 0.9375rem;

            .title {
              font-weight: 300;
              font-size: 0.625rem;
              line-height: 106.4%;
              letter-spacing: 0.1563rem;
              text-transform: uppercase;
              padding-bottom: 0.3125rem;
            }

            .value {
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 106.4%;
              letter-spacing: 0.0125rem;
            }
          }
        }
      }
    }

    .right {
      position: absolute;
      width: 24rem;
      max-height: 22.875rem;
      border-radius: 0.625rem;
      bottom: 0;
      right: 1.875rem;
      z-index: 1;
      padding: 0.3125rem;

      &.box-shadow {
        top: 0.875rem;
      }
      .vp-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .intro-video {
        object-fit: cover;
        border-radius: 0.625rem;
        width: 300px;
        height: 150px;
      }

      .footer {
        display: flex;
        justify-content: flex-end;

        .start-date,
        .cohort-level,
        .cohort-lang {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          width: 40%;
          padding: 0.625rem;

          .title {
            color: $color-light-grey;
            font-size: 0.875rem;
          }

          .body {
            font-size: 1rem;
            font-weight: bold;
            white-space: nowrap;
            color: $color-dark-grey;
            padding-left: 0.3125rem;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        font-size: 1.25rem;

        .button-root {
          background-color: $color-orange-medium;
          height: 3.125rem;
          border-radius: 0.625rem;
          width: 100%;

          .arrow-icon {
            background-color: $arrow-icon-color;
            border-radius: 3.125rem;
            padding: 0.3125rem;
            font-size: 1.125rem;
            right: 1.25rem;
            top: 0.625rem;
          }
        }

        .button-root-disabled {
          // cursor: not-allowed;
          background-color: color-light-grey;
          color: $grey-button-color;
          height: 3.125rem;
          border-radius: 0.625rem;
          width: 100%;
        }

        .status-container {
          color: $color-orange;
          display: flex;
          align-items: center;
          flex-direction: column;

          &.rejected {
            flex-direction: row;
            color: $color-red;
          }

          &.applied {
            color: $color-green;
          }

          svg {
            font-size: 2rem;
            padding-right: 0.3125rem;
          }
        }

        .upload-section {
          font-family: "Nunito";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          cursor: pointer;
          letter-spacing: 0.008em;
          text-decoration-line: underline;

          color: #ffffff;
        }

        .schedule-interview-section {
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          .schedule-interview {
            background: #299ef3;
            border-radius: 6px;
            height: 35px;
            font-family: "Nunito";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 106.4%;

            letter-spacing: 0.2px;

            color: #ffffff;
          }
        }
      }
    }
  }

  .content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2.5rem;
    margin-top: 7.5rem;
    margin-bottom: 2rem;

    .download-link-container {
      display: flex;
      padding-top: 1.25rem;
      float: right;

      .download-link {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        svg {
          padding-right: 0.3125rem;
        }

        .content {
          display: flex;
        }

        &:after {
          content: "";
          margin-top: 0.3125rem;
          display: block;
          margin: auto;
          margin-top: 0.3125rem;
          height: 0.1875rem;
          width: 0rem;
          background: transparent;
          transition: width 0.3s ease, background-color 0.3s ease;
        }

        &:hover {
          &:after {
            width: 95%;
            background: $color-primary-blue;
          }
        }
      }
    }

    .course-details,
    .what-you-learn,
    .requirements,
    .timeline {
      display: flex;
      flex-direction: column;
      padding-top: 2.5rem;

      .label {
        font-size: 2.125rem;
        font-weight: bold;
        letter-spacing: 0.0125rem;
        color: $course-details-label-color;
        padding-bottom: 1.25rem;
      }

      .text {
        white-space: pre-line;
        display: flex;
        flex-direction: column;
      }

      .points {
        white-space: pre-line;
        display: flex;
        flex-direction: column;

        .point {
          display: flex;
          align-items: flex-start;
          padding-bottom: 0.625rem;

          svg {
            padding-right: 0.625rem;
            color: $color-green;
          }
        }
      }
    }

    .what-you-learn {
      .text {
        padding-bottom: 1.25rem;
      }
    }

    .requirements,
    .timeline {
      .text {
        padding-bottom: 1.25rem;
      }

      .points {
        .point {
          align-items: baseline;

          svg {
            font-size: 0.75rem;
            padding-right: 0.625rem;
            color: $color-black;
          }
        }
      }
    }
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 106.4%;

      color: #0e1555;
    }
  }

  .file-upload-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .file-upload {
      width: 80%;
      height: 90px;
      background: #f7f7f7;
      border: 1.5px dashed #0c5e96;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .label {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        text-align: center;

        color: #333333;
      }
    }
  }

  .submit-section {
    display: flex;
    justify-content: center;

    .button {
      background: #142297;
      border-radius: 5px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 106.4%;

      color: #ffffff;
    }
  }
}

.modal-wrapper2 {
  display: flex;
  flex-direction: column;

  align-items: center;

  .schedule-interview-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;

    .label {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 106.4%;

      color: #0e1555;
    }

    .submit-section {
      display: flex;
      justify-content: center;
      justify-content: space-around;

      .button {
        background: #142297;
        border-radius: 5px;
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 106.4%;

        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .cohort-container {
    .cohort-header-container {

      .right {
        width: 18rem !important;
      }
    }

  }
}

@media only screen and (max-width: 700px) {
  .cohort-container {
    .cohort-header-container {
      padding: 0 1.25rem;
      flex-direction: column;
      padding-bottom: 2.5rem;

      .left {
        width: 100%;
      }

      .right {
        width: 100% !important;
        position: static;
      }
    }

    .content-container {
      margin-top: 0.625rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .cohort-container {
    .content-container {
      width: 70%;
    }
  }
}