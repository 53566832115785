@import "../../../../Stylesheets/Utils/fonts.scss";

.student-progress-menu-header {
  display: flex;
  justify-content: flex-start;
  padding: 1.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: #000000;
}

.student-progress-tiles-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .left-side-modules {
    display: flex;
    flex-direction: column;
    width: 30%;
    .student-progress-tiles-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2rem;
      margin-left: 2rem;
      width: 100%;
      height: 3.125rem;
      padding: 1rem;
      background: #ffffff;
      border-radius: 0.5625rem;
      box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      &.selected {
        border: 0.125rem solid #142297;
      }
      &:hover {
        transform: scale(1.05);
        transition: transform 0.2s ease-in-out;
      }

      .student-progress-tiles-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: inherit;
        height: inherit;
        padding: 0;
        position: relative;

        .section-badge {
          position: absolute;
          margin-left: 0.5rem;
          top: 0;
        }

        .module-index {
          background: #142297;
          border-radius: 5px;
          height: inherit;
          width: 15%;
          font-family: "Lato";
          font-style: normal;
          font-weight: 800;
          font-size: 0.75rem;
          line-height: 0.6875rem;

          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
        }
        .student-progress-tile-title {
          display: flex;
          flex-direction: column;
          height: 100%;

          .module-title {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 1rem;
            display: flex;
            align-items: center;

            color: #262626;
          }
        }

        .progress-root {
          width: unset !important;
          height: unset !important;
          color: #38ca89;
          // color: #C0C0C066
        }
        .module-progress {
          display: flex;
          width: 4.6875rem;
          height: 4.6875rem;
        }
      }
    }
  }
  .right-side-information {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
    width: 50%;
    .select-helper-text {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 1.25rem;
      line-height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #262626;
    }
    .module-progress-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .module-options-tiles {
        background: #ffffff;
        box-shadow: 0 0.0625rem 1.25rem rgba(0, 0, 0, 0.15);
        border-radius: 0.625rem;
        width: 18.75rem;
        height: 7.3125rem;
        display: flex;
        flex-direction: row;
        margin-left: 1.25rem;
        margin-top: 1.25rem;
        padding: 1rem;
        align-items: center;
        .left-side-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .images {
            display: flex;
          }
          .label {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 1.25rem;
            line-height: 1.5rem;

            display: flex;
            align-items: center;

            color: #262626;
          }
        }
        .right-side-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 2rem;
          .circular-wrapper {
            display: flex;
            .circle {
              width: 3.125rem;
              height: 3.125rem;
            }
          }
        }
      }
    }
  }
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  .parent-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1.0625rem 1rem;

    .title {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #000000;
      text-transform: capitalize;
      position: relative;
      .status {
        display: flex;
        .check-circle {
          justify-content: flex-end;
          color: #008a06;
        }
      }
      .section-badge {
        position: absolute;
        right: 1rem;
        top: 0.7rem;
      }
    }
    .progress {
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.375rem;
      color: #4d4d4d;
    }
  }
}
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
  font-family: "Lato";
  font-weight: 500;
  line-height: 1.375rem;
}
