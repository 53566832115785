@import '../Utils/colors.scss';

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
        .button-root {
            background-color: $color-primary-blue;
            height: 3.125rem;
            border-radius: 0.625rem;
            width: 15.625rem;

            .arrow-icon {
                background-color: rgba(255, 255, 255, 0.31);
                border-radius: 3.125rem;
                padding: 0.3125rem;
                font-size: 1.125rem;
                right: 1.25rem;
                top: 0.625rem;
            }

            &:hover {
                background-color: $color-primary-blue;
            }
        }
}