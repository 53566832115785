@import "../Utils/colors.scss";

.module-content-main {
  width: 100vw;
  height: 100vh;
  background: none;
}

.module-content-container {
  display: flex;
  flex-direction: row;
  margin-top: 3.4375rem;

  .content-menu-icon {
    visibility: hidden;
  }

  .floating-nav {
    .content-nav {
      display: none;
    }
  }

  .content-nav {
    width: 18.75rem;
    padding: 1.25rem;
    border-right: 0.0625rem solid $color-light-grey;
    height: calc(100vh - 15.625rem);
    overflow: overlay;

    /* Code to remove scroll bar but persist the scroll behavior*/
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    /* Code to remove scroll bar but persist the scroll behavior*/

    .menu-close-icon {
      display: none;
    }

    .nav-module-name {
      font-weight: 700;
      font-size: 1.25rem;
      color: $modules-title-color;
      padding: 0.625rem;
      margin-bottom: 1.25rem;
    }

    .nav-item {
      padding: 0.625rem;
      white-space: pre-wrap;
      border-radius: 0.3125rem;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.008em;
      color: $color-light-black;
      margin: 0.3125rem 0;
      display: flex;
      align-items: center;
      height: 2.5rem;

      &.admin-nav-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-item-actions {
          visibility: hidden;

          .drag-icon {
            cursor: move;
          }
        }

        &.editable {
          .nav-item-actions {
            visibility: visible !important;
          }
        }

        .input-root {
          font-size: 0.875rem;
        }

        &:hover {
          .nav-item-actions {
            visibility: visible;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }
        }
      }

      &:hover,
      &.selected {
        background-color: $very-light-gray;
        font-weight: 600;
        //transition: background-color 0.5s linear ease-in-out;
      }

      .nav-item-title {
        padding: 0 0.625rem;
      }

      .nav-item-weightage {
        color: #03a10a;
        font-weight: 700;
      }

      .tick-icon {
        font-size: 0.875rem;
        background: $tick-icon-color;
        color: $color-white;
        border-radius: 0.625rem;
        padding: 0.125rem;

        &.completed {
          background: $color-light-green;
        }
      }
    }

    .create-content-container {
      text-align: center;

      .create-content-button {
        height: 3.125rem;
        border-radius: 0.625rem;
        padding: 0 1.25rem;
        color: $color-dark-blue;
        width: 100%;

        .arrow-icon {
          background-color: $arrow-icon-color;
          border-radius: 3.125rem;
          padding: 0.3125rem;
          font-size: 1.125rem;
          right: 1.25rem;
          top: 0.625rem;
        }
      }

      .create-section-field {
        .outline-root {
          color: $color-dark-grey;
          width: 100%;
          height: 2.5rem;

          /* Overriding styles was not working through module scss file. So used general.scss to override the styles
                        &.MuiOutlinedInput-notchedOutline {
                            border-color: $color-primary-blue !important;
                            border-radius: 10px !important;
                        }
                    */
          .notchedOutline {
            border: none !important;
          }

          .input-root {
            height: 100% !important;
            border: none !important;
            font-size: 0.875rem;
          }
        }

        .create-section-action-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          margin-top: 1.25rem;

          .create-button,
          .create-button {
            border-radius: 0.375rem;
            padding: 0 0.625rem;
          }

          .cancel-button {
            color: $color-dark-blue;
            margin-right: 0.625rem;
          }

          .create-button {
            background-color: $color-dark-blue;
            color: $color-white;
          }
        }
      }
    }
  }

  .content-body {
    width: calc(100% - 25rem);
    padding: 0 1.125rem;
    overflow-y: overlay;
    height: calc(100vh - 12.5rem);
    margin-top: 2.5rem;

    /* Code to remove scroll bar but persist the scroll behavior*/
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    /* Code to remove scroll bar but persist the scroll behavior*/

    .title {
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: 0.0187rem;
      color: #252733;
      padding: 0.625rem 0;
    }

    .content-body-inside-wrapper {
      position: relative;
    }

    .save-button {
      text-transform: capitalize;
      padding: 0.625rem;
      background-color: $create-module-hover-color;
      color: $color-primary-blue;
      height: 2.125rem;
      border-radius: 0.3125rem;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 7.5rem;

      .save-icon {}
    }

    .module-section-nav-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 1.5rem 0;
      margin-bottom: 0;

      &.no-mark-complete {
        margin-bottom: 4rem;
      }

      .nav-prev,
      .nav-next {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .mark-complete-btn {
      display: flex;
      justify-content: center;

      .button-root {
        background-color: $color-dark-blue;
        margin: 0.5rem;
        border-radius: 0.625rem;
        font-size: 0.875rem;
        height: 3.125rem;
        margin-bottom: 2rem;
      }
    }


    .title {
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: 0.0187rem;
      color: #252733;
      padding: 0.625rem 0;
      word-break: break-all;
    }

    .card-video-player {
      width: 100%;
      height: 60vh;
    }

    .module-section-video-player,
    .content-video-preview {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;

      .module-section-video-preview {
        width: 100%;
        height: 100%;
      }
    }

    .video-preview-wrapper {
      display: flex;
      flex-direction: column;

      .button-styles {
        width: max-content;
        margin-left: 0;
      }

      .video-add-on-features {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;

        >label {
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0.0187rem;
          text-transform: capitalize;

          .setOptionsRadio {
            margin-left: 0;
            margin-right: 0.3125rem;
          }
        }
      }
    }

    .content-video-upload-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 0.625rem;

      .form-control-root {
        margin-left: 0;
        height: 3.125rem;
        min-width: 19.375rem;
        background: #ffffff;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .nav-module-name {
    // background: rgba(0,0,0,.5);
  }

  .module-content-container {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    position: relative;

    .content-menu-icon {
      visibility: visible;
      position: absolute;
      top: 0.625rem;
      right: -0.625rem;
      cursor: pointer;
    }

    .content-nav {
      display: none;
    }

    .content-body {
      width: calc(100%);
      padding: 0;
      overflow-y: overlay;
      height: calc(100vh - 12.5rem);
      margin-top: 2.5rem;
      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 18rem);
      }

      /* Code to remove scroll bar but persist the scroll behavior*/
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }

      /* Code to remove scroll bar but persist the scroll behavior*/

      .title {
        font-weight: 700;
        font-size: 1.5rem;
        letter-spacing: 0.0187rem;
        color: #252733;
        padding: 0.625rem 0;
      }

      .card-video-player {
        width: 100%;
        height: 60vh;
      }

      .module-section-video-player,
      .content-video-preview {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;

        .module-section-video-preview {
          width: 100%;
          height: 100%;
        }
      }

      .content-video-upload-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;

        .form-control-root {
          margin-left: 0;
          height: 3.125rem;
          min-width: 19.375rem;
          background: #ffffff;
          margin-right: 0;
        }
      }

      .content-body-inside-wrapper {
        position: relative;
      }

      .save-button {
        text-transform: capitalize;
        padding: 0.625rem;
        background-color: rgba(25, 118, 210, 0.1);
        color: #0e1555;
        height: 2.125rem;
        border-radius: 0.3125rem;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 7.5rem;

        .save-icon {}
      }

      .module-section-nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 1.5rem 0;
        margin-bottom: 0;

        .nav-prev,
        .nav-next {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: small;
        }
      }

      .mark-complete-btn {
        .button-root {
          background-color: $color-dark-blue;
          margin: 0.5rem;
          border-radius: 0.625rem;
          font-size: 0.675rem;
          height: 2.5rem;
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .nav-module-name {
      // background: rgba(0,0,0,.5);
    }

    .module-content-container.with-hidden-nav {
      .floating-nav {
        width: 100vw;
        position: absolute;
        height: 100vh;
        z-index: 10;
        background: $floating-nav-color;

        .content-nav {
          display: revert !important;
          position: absolute;
          z-index: 1;
          background-color: $color-white;
          height: 100vh;
          left: -1.25rem;

          .menu-close-icon {
            width: 100%;
            text-align: right;
            cursor: pointer;
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .module-content-container {
    .content-nav {
      left: -1.25rem;
      width: calc(100vw - 2.5rem);
    }
  }
}