@import "../Utils/colors.scss";

.backto-container {
  margin-top: 40px;
  padding: 20px 2.5rem;
  padding-left: 0.9375rem;
  display: flex;
  align-items: center;
  color: $color-white;
  position: fixed;
  font-size: 0.875rem;
  width: 100%;
  top: 1.875rem;
  z-index: 1;
  height: 0.9375rem;
  background: url(../../Assets/Images/banner.png);
  

  svg {
    font-size: 24px;
    padding-right: 10px;
    font-weight: normal;
    color: $color-white;
  }

  .backto-text {
    padding-right: 0.625rem;
  }

  &:hover {
    font-weight: 600;
  }
}
