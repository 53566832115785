
.img-modal-root {
    .img-modal-paper {
        background-color: transparent;
        max-width: 90%;
        box-shadow: none;
        max-height: 50%;
        display: flex;
        justify-content: center;
        .img-modal-body {
            min-width: 50%;
            max-width: 80%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
        .img-body {
            max-width: 100%;
            object-fit: contain;
            height: 100%;
        }
    }
}