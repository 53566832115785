@import "../../../Utils/colors.scss";

.container-root {
  width: calc(100% - 4rem) !important;
  min-height: 53.125rem;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: #ffffff;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);

  .header {
    .students-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5rem;
      letter-spacing: 0.02em;
      color: #000000;
    }

    .search {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      .download-button {
        background: #142297;
        border-radius: 6px;
        width: 160px;
        margin-right: 20px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        display: flex;
        align-items: center;

        color: #ffffff;
      }
      .search-field-root {
        width: 18.75rem;
      }
    }
  }
  .filter-div {
    display: flex;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    margin: 1rem;
    align-items: center;

    .total-text,
    .current-page {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      margin: 1rem;
      color: #262626;
    }
    .current-page {
      &-number {
        font-weight: 400;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3rem;
    .previous-btn {
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;

      display: flex;
      align-items: center;
      letter-spacing: -0.12px;

      color: #142297;
      background: #ffffff;
      border-radius: 5px;
      &.disabled {
        color: #a5a6b6;
      }
    }
    .next-btn {
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.12px;
      margin-left: 20px;
      color: #142297;
      background: #ffffff;
      border-radius: 5px;
      &.disabled {
        color: #a5a6b6;
      }
    }
  }

  .applications-header-root {
    .header-row {
      .header-cell {
        background-color: #e2e7ee;
        color: #595959;
        text-align: center;
      }
    }
  }

  .body-cell {
    text-align: center;

    &.name {
      text-align: left;
      .section-badge {
        margin-bottom: 0.3125rem;
      }
      .name-container {
        display: flex;
        align-items: center;

        .profile-img {
          height: 1.875rem;

          .name-letters {
            background-color: #0e1555;
            color: white;
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 3.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.3125rem;
            font-size: 0.75rem;
            text-align: center;
          }

          .user-image {
            height: 100%;
            object-fit: contain;
            border-radius: 3.125rem;
            margin-right: 0.3125rem;
          }
        }

        .name-text {
          text-align: left;
        }
      }
    }

    .view-progress-button {
      width: 88px;
      height: 28px;

      background: #fac30f;
      border: 1px solid #fac30f;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 11px;
      line-height: 13px;

      display: flex;
      align-items: center;

      color: #ffffff;
    }
  }
}
