.dialog-modal {
  display: flex;
  .modal-paper {
    max-width: unset !important;
    max-height: unset !important;
    margin: unset;
    border-radius: unset;
    overflow: hidden;
  }
}

.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;

  .crop-image-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 25rem;
    .React-crop {
      display: flex;
      position: relative;
      width: 100%;
      > .cropper-container {
        .cropper-wrap-box {
          .cropper-canvas img {
            max-width: 100% !important;
            max-height: 100% !important;
          }
        }
      }

      &-image {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .modal-header {
    background: #142297;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2.125rem;
    color: #ffffff;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;

    .save-btn {
      background: #fac30f;
      align-items: center;
      color: #fff;
      display: flex;
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      padding: 1rem;
      letter-spacing: 0.01em;
      line-height: 1.0625rem;
      text-align: center;
      width: 10rem;
    }

    .cancel-btn {
      align-items: center;
      border-radius: 6px;
      color: #fff;
      display: flex;
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      padding: 1rem;
      letter-spacing: 0.01em;
      line-height: 1.0625rem;
      text-align: center;
      width: 10rem;
      background: #a8a8a8;
    }
  }
}
