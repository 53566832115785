@import "../Utils/colors.scss";

.backto-container {
  margin-top: 2.5rem;
  padding: 1.25rem 2.5rem;
  padding-left: 0.9375rem;
  display: flex;
  align-items: center;
  color: $color-white;
  position: fixed;
  font-size: 0.875rem;
  width: 95%;
  top: 1.875rem;
  z-index: 1;
  height: 0.9375rem;
  background: url(../../Assets/Images/banner.png);
  cursor: pointer;

  svg {
    font-size: 1.5rem;
    padding-right: 0.625rem;
    font-weight: normal;
    color: $color-white;
  }

  &:hover {
    font-weight: 600;
  }
}

.container {
  margin: 0 auto;
  width: 70%;
  margin-top: 5rem;

  .cohort-module-title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .phase {
    position: relative;
    box-shadow: 0rem 0.0625rem 0.9375rem rgba(14, 21, 85, 0.2);
    border-radius: 0.3125rem;
    margin: 2.5rem 0;

    .phase-header {
      .phase-header-title {
        background: $color-primary-blue;
        color: $color-white;
        padding: 0.625rem;
        position: absolute;
        border-top-left-radius: 0.625rem;
      }
    }
  }

  .modules-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;

    .module {
      position: relative;
      box-shadow: rgb(100 100 111 / 20%) 0rem 0rem 0.625rem 0.0625rem;
      border-radius: 0.3125rem;
      width: 90%;
      padding: 1.25rem;
      margin-top: 2.5rem;
      cursor: pointer;

      &.locked {
        cursor: not-allowed;
      }

      &:first-child {
        margin-top: 2.75rem;
      }

      &:last-child {
        margin-bottom: 2.5rem;
      }

      .module {
        &-header {
          width: 60%;
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 1.125rem;
          /* identical to box height */

          letter-spacing: 0.1875rem;
          text-transform: uppercase;

          color: #0e1555;
        }

        &-lock {
          position: absolute;
          right: 1.25rem;
          top: 1.25rem;
        }

        &-progress {
          position: absolute;
          right: -1.25rem;
          top: -2.25rem;

          .progress-root {
            width: unset !important;
            height: unset !important;
            color: #38ca89;
          }

          .svg-styles {
            background: radial-gradient(ellipse at center,
                #ffffff 0%,
                #ffffff 56%,
                #c0c0c066 59%,
                #c0c0c066 100%);
            border-radius: 50%;
          }

          .svg-styles,
          .svg-path {
            width: 75px;
            height: 75px;
          }
        }

        &-title {
          width: 60%;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0.0125rem;
          padding: 0.9375rem 0;

          color: #121212;
        }

        &-desc {
          padding-top: 1rem;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 130%;
          /* or 1rem */
          width: 75%;
          letter-spacing: 0.0125rem;

          color: #4d4d4d;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .container {
    width: 90%;

    .modules-container {
      .module {
        &:last-child {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
}