.generic-modal-paper {
  width: 100% !important;
  // max-width: 900px !important;
  .close-container {
    text-align: right;
    padding: 0.3125rem;
    cursor: pointer;
  }
}

.welcome-modal-bg {
  background: url("../../Assets/Images/ASA-BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 1600px) {
    max-width: 37%;
  }
  @media screen and (max-width: 1600px) {
    max-width: 46%;
  }
  @media screen and (max-width: 1200px) {
    max-width: 65%;
  }
  @media screen and (max-width: 768px) {
    max-width: 85%;
  }
  @media screen and (max-width: 425px) {
    background-position: center;
    max-width: 85%;
  }
}
