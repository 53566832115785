.profile-page {
  background: #f7f7fc;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
}

.export-section {
  display: flex;
  flex-direction: row-reverse;
  padding: 1.5rem 2.5rem 0 0;
}

.export-button {
  border-radius: 8px;
  background: #142297;
  text-transform: none;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    background: #142297;
    color: #ffffff;
  }
}

.student-profile-banner {
  background: url(../../../../../Assets/Images/banner.png);
  border-bottom-left-radius: 3.125rem;
  background-size: contain;
  height: 20vh;
}

.profile-banner-back {
  height: 30%;
  color: white;
}

.profile-banner-content {
  display: flex;
  height: 70%;
  margin-left: 100px;
}

.profile-camera-badge {
  width: 10.625rem;
  height: 10.625rem;
}

.profile-banner-photo {
  height: 100%;
  width: 100%;
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 1px 4px #b9b7ff);
}

.badge-background {
  padding: 8px;
  border-radius: 50%;
  background: #0e1555;
  border: 2px solid #ffffff;
  height: auto;
}

.profile-camera-logo {
  color: white;
}

.profile-banner-info {
  color: white;
  margin: 34px 20px;
}

.profile-banner-info-name {
  font-size: 32px;
  font-weight: 700;
}

.add-button-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

// ----------------------- Profile Body -----------------------------------

.student-profile-body {
  margin: 123px auto 0 auto;
  margin-left: 100px;
  margin-right: 100px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}

.personal-details-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.personal-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-header {
  color: white;
  font-size: 16px;
  letter-spacing: 0.005em;
  width: 200px;
  height: 50px;
  background: #0e1555;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  align-items: center;
}

.profile-header-first {
  position: relative;
}

.profile-header-name {
  margin-left: 40px;
}

.personal-edit {
  padding: 18px;
}

.personal-add-button {
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 8px;
  background: #142297;
  text-transform: none;
  color: #ffffff;
  font-size: small;

  &:hover {
    background: #142297;
  }
}

.details-box-wrapper {
  display: flex;
  margin: 0 40px;
  flex-wrap: wrap;
}

.details-box-name {
  display: flex;
  flex-basis: 50%;
}

.content-heading-name {
  font-weight: bold;
  white-space: nowrap;
  padding: 10px;
}

.content-data-name {
  padding: 10px;
  white-space: nowrap;
}

.details-box {
  display: flex;
  width: 100%;
  margin: 0 40px;
  flex-wrap: wrap;
  align-items: center;
  &.Bio {
    align-items: unset;
  }
}

.content-heading {
  font-weight: bold;
  white-space: nowrap;
  padding: 10px;
  text-transform: capitalize;
}

.content-data {
  padding: 10px 10px ;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  display: flex;
  word-break: break-all;
  width: 80%;
  align-items: center;
}

.prompts-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.prompt {
  max-width: 500px;
  background-color: rgba(217, 217, 217, 1);
  margin: 0.5rem;
  border-radius: 20px;

  &:hover {
    transform: scale(1.03);
    transition: transform 0.2s ease-in-out;

    .options-section {
      visibility: visible;
    }
  }
}

.question {
  font-family: "Nunito";
  font-weight: 700;
  margin: 1rem;
  font-size: medium;
  margin-top: 0.5rem;
}

.skills-section {
  display: flex;
  flex-direction: row;
}

.answer {
  margin: 1rem;
  max-height: 45px;
  overflow: hidden;
  font-size: medium;
}

.answer-long {
  margin: 1rem;
}

.view-more {
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  cursor: pointer;
}

.label {
  font-family: "Nunito";
  font-weight: 700;
  margin: 1rem;
  font-size: 18px;
}

.skill-display-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.skill {
  max-width: 200px;
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;
}

.count-indicator {
  color: #142297;
  padding-right: 5px;
}

.options-section{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.4rem;
  visibility: hidden;
}
//---------------prompts modal---------------------
.heading-section {
  display: flex;
  align-items: center;
  background-color: rgba(20, 34, 151, 1);
  color: rgba(255, 255, 255, 1);
  font-family: "Lato";
  font-weight: 700;
  font-size: 18px;
  height: 30px;
  padding: 1rem;
}

.prompt-answer {
  width: 95%;
  height: 100px;
}

.error-prompt-answer {
  width: 95%;
  height: 100px;
  border: 0.1rem solid red;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
  margin: 1rem;
}

.submit-button {
  height: 35px;
  width: 100px;
  background-color: rgba(241, 197, 69, 1);
  font-family: "Lato";
  font-weight: 700;
  font-size: 14px;
  color: white;

  &:hover {
    background-color: rgba(241, 197, 69, 1);
  }

  &.disbaled {
    background-color: rgba(217, 217, 217, 1);
  }
}

.cancel-button {
  height: 35px;
  width: 100px;
  border: 1px solid rgba(37, 37, 37, 1);
  color: rgba(37, 37, 37, 1);
  margin: 0 10px;
}

// ----------------------skills---------------------
.skills-content-box {
  display: flex;
  margin: 20px 40px 30px;
  flex-flow: wrap;
}

.skills-content {
  color: #595959;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #f5f5f5;
  padding: 1px 8px;
  margin-right: 10px;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 20px;
}

// ----------------------Professional---------------------
.profession-box {
  margin: 35px 15px 40px 40px;
}

.profession-position {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.profession-position-icons {
  display: flex;
  align-items: center;
}

.position-icon {
  margin: 0 0 0 19px;
  > svg {
    font-size: 1.2rem;
  }
}

.profession-date {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profession-bio {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  display: flex;
  word-break: break-all;
  width: 80%;
}

.profession-current {
  color: #03a20a;
  font-size: 14px;
  line-height: 22px;
}

.profile-checkbox {
  color: #03a20a;
  padding-left: 0px;
}

// ---------------------------Modals style --------------------------
.dialog-modal {
  align-items: flex-start;
}

.modal-paper {
  border-radius: 7px;
  max-width: unset;
  width: 1000px;
}

.modal-personal-box {
  margin: 15px;
}

.modal-personal-heading {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
  justify-content: center;

  .modal-heading-close {
    position: absolute;
    right: 0;
  }
}

.modal-personal-fistLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.modal-personal-secondLine{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.modal-personal-content {
  width: 30%;
  margin: 30px 15px 10px;

  > label {
    color: #0e1555;
  }

  > div {
    border-radius: 8px;
    color: #44444f;
    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}
.modal-personal-location-content{
 margin: 30px 15px 10px;
  width: 290px;
  > label {
    color: #0e1555;
  }

  > div {
    border-radius: 8px;
    color: #44444f;
    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-personal-bio {
  width: calc(100% - 30px);
  height: 92px;
  margin: 30px 15px 10px;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.add-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 20px;
  color: #142297;
}

.modal-links-add {
  padding-right: 5px;
}

.modal-personal-text {
  margin: 30px 15px 5px;
  color: #262626;
}

.modal-personal-checkbox-container {
  font-size: small;
  display: flex;
  align-items: center;
}

.modal-personal-save-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-personal-save {
  padding: 14px 24px;
  background: #142297;
  border-radius: 8px;
  width: 80px;
  height: 48px;
  &:disabled {
    background: grey;
    cursor: not-allowed;
  }
}


.modal-personal-save:hover {
  background: #142297;
}

.modal-personal-checkbox {
  color: #142297 !important;
}

// -------------modal professional Details ----------

.modal-professional-box {
  margin: 15px;
}

.modal-professional-heading {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
  justify-content: center;

  .modal-heading-close {
    position: absolute;
    right: 0;
  }
}

.modal-professional-textbox {
  width: calc(100% - 30px);
  margin: 30px 15px 10px;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-professional-bio {
  height: 92px;
}

.modal-professional-fistLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-professional-content {
  width: 30%;
  margin: 30px 15px 10px;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-professional-text {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.modal-professional-add-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px;
}

.modal-professional-add {
  padding: 14px 24px;
  background: #142297;
  border-radius: 8px;
  width: 80px;
  height: 48px;
}

.modal-professional-add:hover {
  background: #142297;
}

.modal-professional-checkbox {
  color: #142297 !important;
}

// -------------modal Educational details--------------

.modal-Edu-box {
  margin: 15px;
}

.modal-Edu-heading {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
  justify-content: center;

  .modal-heading-close {
    position: absolute;
    right: 0;
  }
}

.modal-Edu-textbox {
  width: calc(100% - 30px);
  margin: 30px 15px 10px;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-Edu-fistLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.achivements-parent-wrapper, .links-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .achivements-parent, .self-link-parent {
    display: flex;
    justify-content: flex-end;
  }
  .achievements-delete-wrapper, .self-link-delete-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .achievements-wrapper, .self-links-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 30%;
      .achievements, .self-links-content {
        margin-top: 15px;
        margin-right: 0;
        width: 100%;
      }
      .delete-container {
        cursor: pointer;
      }
    }
  }
}

.modal-Edu-content {
  width: 30%;
  margin: 30px 15px 10px;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-Edu-text {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.modal-Edu-add-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px;
}

.modal-Edu-add {
  padding: 14px 24px;
  background: #142297;
  border-radius: 8px;
  width: 80px;
  height: 48px;
}

.modal-Edu-add:hover {
  background: #142297;
}

.modal-Edu-checkbox {
  color: #142297 !important;
}

// --------------- skills Modal ----------------
.skills-wrapper {
  margin: 15px;
}

.modal-skills-heading {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: relative;
  justify-content: center;

  .modal-heading-close {
    position: absolute;
    right: 0;
  }
}

.skills-search-text {
  margin: 30px 30px 0px;
}

.skills-auto-box {
  width: calc(100% - 60px);
  margin: 30px 30px 10px;
  height: auto;

  > div {
    border-radius: 8px;
    color: #44444f;

    > fieldset {
      border-color: #0e1555 !important;
    }
  }
}

.modal-skills-add-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px;
}

.modal-skills-add {
  padding: 14px 24px;
  background: #142297;
  border-radius: 8px;
  width: 80px;
  height: 48px;
}

.no-skills-found {
  display: flex;
  flex-direction: row;
  margin: 1rem 0 0 1rem;
}

.redirection {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  color: #0e1555;
  font-weight: 600;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
  }
}

.input-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.button-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
}
.certificate-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem 1rem;

}
.certificate{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 6.25rem;
  width: 10.25rem;
  justify-content: space-between;
  padding: 0.75rem;
  cursor: pointer;
  margin-left: 2rem;
  color: #0e1555;
  font-weight: 600;
  font-size: 14px;
  background: #f5f5f5;
  border-radius: 5px;
  &:hover{
    transform: scale(1.13);
    transition: transform 0.2s ease-in-out;
  }
}
.badge-image{
  height: 110px;
  width: 110px;
}
.badge-canvas{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  margin-left: 2rem;
}
.pdf-image{
  height: 90%;
  width: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 900px) {
  .student-profile-body {
    margin: 0.625rem 1.25rem;
    margin-top: 5rem;
  }

  .profile-camera-badge {
    width: 100px;
    height: 100px;
    left: 0;
  }

  .profile-banner-content {
    margin-left: 2.5rem;
    margin-top: 2.5rem;
  }

  .profile-banner-info-name {
    font-size: large;
  }

  .profile-header {
    height: 40px;
    font-size: medium;
  }

  .personal-details-box {
    font-size: small;
  }

  .details-box {
    margin: 0 20px;
  }

  .details-box-wrapper {
    margin: 0 20px;
  }

  .modal-personal-content,
  .modal-professional-content,
  .modal-Edu-content {
    width: 45%;
  }

  .dialog-modal {
    margin-top: 40px;
  }

  .modal-personal-text {
    font-size: medium;
  }
}
.achievement-section{
  display: flex;
  flex-direction: row;
  font-weight: 400;
  align-items: center;
}
.achievement{
  margin-left: 0.5rem;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #595959;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #f5f5f5;
  padding: 1px 8px;
  margin-right: 10px;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 20px;
}

@media only screen and (max-width: 600px) {
  .profile-banner-info-name {
    font-size: medium;
  }

  .profile-header {
    height: 40px;
    font-size: small;
  }

  .modal-personal-content,
  .modal-professional-content,
  .modal-Edu-content {
    width: calc(100% - 30px);
  }

  .dialog-modal {
    margin-top: 0px;
  }

  .question,
  .answer {
    font-size: small;
  }
}

@media only screen and (max-width: 300px) {
  .profile-banner-info-name {
    font-size: small;
  }
}
