@import "../Utils/colors.scss";
@import "../Utils/fonts.scss";

.icon-text-wrapper {
    display: flex;
    align-items: center;

    &.ReSubmit {
        color: $color-orange !important;
    }

    &.Completed {
        color: $color-green;
    }

    &.Failed {
        color: $color-red;
    }

    .menu-icon {
        font-size: 1.25rem !important;
    }

    .Submitted {
        margin-left: 0.625rem;
    }

    .ReSubmit {
        margin-left: 0.625rem;
        color: $color-orange !important;
    }

    .Completed {
        margin-left: 0.625rem;
        color: $color-green;
    }
}
