@import "../Utils/colors.scss";
@import "../Utils/fonts.scss";
.main-container {
  width: calc(100% - 2rem) !important;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: $color-white;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
  overflow: hidden;
  .settings-header {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.5rem;

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
    padding-left: 1.375rem;

    color: #000000;
  }
  .settings-wrapper {
    display: flex;
    flex-direction: column;

    .option-wrapper {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      align-items: center;
      width: 50%;
      @media only screen and (max-width: 1200px) {
        width: 90%;
      }
      &:hover {
        transform: scale(1.02);
        transition: transform 0.2s ease-in-out;
      }
      .icon {
        width: 50px;
        height: 50px;
        background: #fefefe;
        border-radius: 57px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .label {
        width: 424px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #f1f2f6;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
        .label-text {
          margin: 1rem;
          font-family: "Lato";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  .modal-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #000000;
  }
  .modal-sub-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #464545;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    justify-content: space-between;
    .cancel-btn {
      width: 160px;
      height: 40px;

      background: #a8a8a8;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }
    .save-btn {
      width: 160px;
      height: 40px;

      background: #fac30f;
      border-radius: 6px;

      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }
  }
}
