@import "../Utils//colors.scss";

.MuiOutlinedInput-notchedOutline {
  border-color: lightgray !important;
  // border-radius: 0.625rem !important;
}

.MuiRadio-root {
  .Mui-checked {
    color: $color-primary-blue !important;
  }
}

// .MuiContainer-root {
//     padding: 0 !important;
//     margin-left: unset !important;
//     box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
// }

.MuiFormControl-root {
  height: 3.125rem;

  .MuiOutlinedInput-root.MuiInputBase-root {
    height: 100%;
  }
}

// .MuiOutlinedInput-notchedOutline {
//     border-radius: 3px !important;
// }

// .MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-root {
//     display: flex;
//     margin: 0 auto;
// }

.route-container {
  // height: calc(100% - 1.875rem);
  // overflow: hidden;
  background: #f4f4f4;

  .comp-with-nav {
    margin-top: 4.375rem;
    overflow: auto;
    height: calc(100vh - 6.25rem);

    &.no-footer {
      height: calc(100vh - 4.375rem);
      margin-left: 15.625rem;
      margin-right: 32px;
    }

    &.no-scroll {
      overflow: hidden;
    }
  }
}

.footer {
  height: 1.25rem;
  //background: $color-primary-blue;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0.3125rem 0;
  font-size: 0.75rem;

  .footer-text {
    color: black;
  }
}

.social-auth-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.25rem;
  text-align: center;
  padding: 0.625rem;
}

.cropper-canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  >img {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1.875rem white inset !important;
}

@media only screen and (max-width: 767px) {
  .route-container {

    // height: calc(100% - 1.875rem);
    // overflow: hidden;
    .comp-with-nav {
      margin-top: 4.375rem;
      overflow: auto;
      height: calc(100vh - 6.25rem);

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 11rem);
      }


      &.no-footer {
        height: calc(100vh - 4.375rem);
        margin-left: 0.625rem;
      }

      &.no-scroll {
        overflow: hidden;
      }
    }
  }
}

.ck.ck-balloon-panel {
  z-index: 1400;
}

.pac-container {
  z-index: 10000 !important; /* Use a high value to ensure it's on top */
}

.pac-container .pac-logo {
  display: none !important;
}
