.main-div {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    background-image: url("../../../../Assets/Images/banner.png");
    height: 90px;

    .setting-header {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 130%;
      display: flex;
      align-items: center;
      letter-spacing: 0.008em;
      color: #ffffff;
      margin: 3rem;
    }
  }

  .menu-display {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;

    .help-form-wrapper {
      display: flex;
      flex-direction: column;
      margin: 2rem;
      width: 100%;

      .menu-header {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.4375rem;
        color: #192547;
        display: flex;
        flex-direction: column;
      }

      .menu-sub-header {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #141414;
      }

      .form-wrapper {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .help-banner {
          margin-top: 5%;
          visibility: visible;
          display: unset;

          .banner {
            width: 100%;
            height: 288px;
          }
        }
      }
    }
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;

  .modal-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #000000;
  }

  .modal-sub-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 1rem;
    display: flex;
    align-items: center;

    color: #464545;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    justify-content: space-between;

    .cancel-btn {
      width: 160px;
      height: 40px;

      background: #a8a8a8;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }

    .save-btn {
      width: 160px;
      height: 40px;

      background: #fac30f;
      border-radius: 6px;

      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 900px) {
  .main-div {
    .menu-display {
      .help-form-wrapper {
        align-items: center;

        .menu-sub-header {
          text-align: center;
        }

        .form-wrapper {
          .help-banner {
            visibility: hidden;
            display: none;
          }
        }
      }
    }
  }
}